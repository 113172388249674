<template>
  <div
    class="attachment"
    :class="fileTypeInfo && fileTypeInfo.typename === 'pdf' && 'is-pdf'"
  >
    <object
      v-if="attachment.DocumentStream && fileTypeInfo"
      :key="attachment.AttachmentName"
      :type="fileTypeInfo.mime"
      :data="attachmentData"
    >
      <p />
      <!-- Empty tag as fallback for when browser can't display attachment data -->
    </object>
  </div>
</template>
  
<script>
/**
 * @fileoverview View Attachment
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import { toUint8Array } from "js-base64";
import { filetypeinfo } from "magic-bytes.js";

export default {
  name: "ViewAttachment",
  props: {
    attachment: {
      type: Object,
    },
  },
  computed: {
    attachmentData() {
      return `data:${
        this.fileTypeInfo ? this.fileTypeInfo.mime : "text/plain"
      };base64,${this.attachment.DocumentStream}`;
    },
    fileExtension() {
      if (!this.attachment.AttachmentName) return;
      return this.attachment.AttachmentName.split(".").pop();
    },
    fileTypeInfo() {
      const fileBuffer = toUint8Array(this.attachment.DocumentStream);
      const info = filetypeinfo(fileBuffer);

      const infoByExtension = info.find(
        (i) => i.extension === this.fileExtension
      );
      if (infoByExtension) return infoByExtension;

      return info[0];
    },
  },
  mounted() {
    this.$emit("mounted");
  },
};
</script>
  
<style lang="scss" scoped>
.attachment {
  display: block;
  margin: 2em 0;
  position: relative;
  width: fit-content;
  height: fit-content;
  &.is-pdf {
    min-height: 50vh;
    height: 100%;
    width: 100%;
    object {
      min-height: 50vh;
      height: 100%;
      width: 100%;
    }
  }
}
</style>