<template>
  <div>
    <v-progress-circular indeterminate />
    <Overlay />
  </div>
</template>

<script>
/**
 * @fileoverview Fullscreen Loader component
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import Overlay from "@/qux-common/qux22/components/atoms/overlay";

export default {
  name: "FullscreenLoader",
  components: { Overlay },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  z-index: 201;
  position: absolute;
  top: 50%;
  left: 50%;
  &::v-deep {
    .v-progress-circular__overlay {
      stroke: $qvo-color-blue-400;
    }
    .v-progress-circular__underlay {
      stroke: white;
    }
  }
}
</style>