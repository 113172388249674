<template>
  <dataTable
    :headers="columns"
    :items="isLoading ? [] : taskList[label]"
    itemKey="CreateDateTime"
    :itemsPerPage="itemsPerPage"
    :loading="isLoading"
    :overrides="{ itemCount, page: this.table.page }"
    @click:row="openTask"
    @pageChange="updatePagination"
    @update:sort-by="updateSortBy"
    @update:sort-desc="updateSort"
  >
    <template #item.CreateDateTime="{ item }">
      {{ formatDate(item.CreateDateTime) }}
    </template>
    <template #item.NextRecoveryDateTime="{ item }">
      {{ formatDate(item.NextRecoveryDateTime) }}
    </template>
    <template #item.NextRegulatoryDateTime="{ item }">
      {{ formatDate(item.NextRegulatoryDateTime) }}
    </template>
    <template #item.ClaimAmount="{ item }">
      {{ formatAmount(item.ClaimAmount) }}
    </template>
  </dataTable>
</template>
        
<script>
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";

export default {
  components: {
    dataTable,
  },
  props: {
    itemCount: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      columns: [
        {
          text: "Claim ID",
          value: "ClaimId",
        },
        {
          text: "Claim Type",
          value: "ClaimType",
        },
        {
          text: "Claim Category",
          value: "ClaimCategory",
        },
        {
          text: "Create Date",
          value: "CreateDateTime",
        },
        {
          text: "Next Recovery Deadline",
          value: "NextRecoveryDateTime",
        },
        {
          text: "Next Regulatory Deadline",
          value: "NextRegulatoryDateTime",
        },
        {
          text: "Age",
          value: "ClaimAge",
        },
        {
          text: "Amount",
          value: "ClaimAmount",
        },
      ],
      table: {},
    };
  },
  computed: {
    tableDefaults() {
      return {
        page: 1,
        itemsPerPage: this.itemsPerPage,
        sortBy: "CreateDateTime",
        sort: "ASC",
      };
    },
    isLoading() {
      return this.pendingCalls.includes("/task/list");
    },
    ...mapGetters({
      activeClientId: "getActiveClientId",
      pendingCalls: "getPendingCallList",
      taskList: "getTaskList",
    }),
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    openTask(task) {
      this.$emit("openTask", {
        ClaimId: task.ClaimId,
        TaskLabel: task.TaskLabel,
      });
    },
    retrieveTaskList() {
      if (this.label) {
        this.$store.dispatch("retrieveTaskList", {
          ClientId: this.activeClientId,
          PageNum: this.table.page,
          PageSize: this.table.itemsPerPage,
          SortBy: this.table.sortBy,
          SortType: this.table.sort,
          TaskLabel: this.label,
        });
      }
    },
    updatePagination({ page, itemsPerPage }) {
      this.table.page = page;
      this.table.itemsPerPage = itemsPerPage;
    },
    updateSort(isDesc) {
      this.table.sort = isDesc ? "DESC" : "ASC";
    },
    updateSortBy(column) {
      this.table.sortBy = column;
      this.table.sort = "ASC";
    },
  },
  mounted() {
    this.table = { ...this.tableDefaults };
    this.retrieveTaskList();
  },
  watch: {
    table: {
      handler() {
        this.retrieveTaskList();
      },
      deep: true,
    },
    label() {
      if (this.label) {
        this.retrieveTaskList();
        this.table = { ...this.tableDefaults };
      }
    },
  },
};
</script>
        
 <style lang="scss" scoped>
.v-data-table {
  &::v-deep {
    tr {
      cursor: pointer;
    }
  }
}
</style>