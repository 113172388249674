<template>
  <div class="execute-accounting">
    <div
      class="reasons"
      v-for="(tasksForReason, reason) in tasksByReason"
      :key="reason"
    >
      <splitHeader :fields="headerFields(tasksForReason)" />
      <subHeaderFields :fields="subHeaderFields(tasksForReason)" />
      <disputesTable
        :actions="actions"
        :claimId="claimId"
        :columns="tableColumns"
        :items="tasksForReason"
        :isLoading="isLoading"
        showSelect
      />
    </div>
  </div>
</template>
        
<script>
import { formatAmount } from "@/util/format";

import disputesTable from "./disputes-table";
import splitHeader from "@/components/split-header";
import subHeaderFields from "@/components/sub-header-fields";

export default {
  components: { disputesTable, splitHeader, subHeaderFields },
  props: {
    actions: {
      type: Array,
    },
    claimId: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: [
        {
          text: "Dispute ID",
          value: "DisputeId",
        },
        {
          text: "Amount",
          value: "Detail.Amount",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
        {
          align: "end",
          value: "menu",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    tasksByReason() {
      if (!this.tasks) return [];
      if (!this.tasks.length) return [];

      const tasksByReason = {};

      this.tasks.forEach((task) => {
        const reason = task.Detail.Reason;
        if (!tasksByReason[reason]) {
          tasksByReason[reason] = [];
        }
        tasksByReason[reason].push(task);
      });

      return tasksByReason;
    },
  },
  methods: {
    formatAmount(amount) {
      return formatAmount(amount);
    },
    headerFields(tasks) {
      const { Reason } = tasks[0].Detail;

      let amount = 0;

      amount = tasks.reduce((accumulator, task) => {
        return Number(accumulator) + Number(task.Detail.Amount);
      }, 0);

      return [Reason, formatAmount(amount)];
    },
    subHeaderFields(tasks) {
      const {
        CreditAccountNumber,
        CreditCollection,
        DebitAccountNumber,
        DebitCollection,
      } = tasks[0].Detail;
      return [
        { label: "Debit Collection", data: DebitCollection },
        {
          label: "Debit Account #",
          data:
            DebitAccountNumber &&
            `X${DebitAccountNumber.substring(DebitAccountNumber.length - 4)}`,
        },
        { label: "Credit Collection", data: CreditCollection },
        {
          label: "Credit Account #",
          data:
            CreditAccountNumber &&
            `X${CreditAccountNumber.substring(CreditAccountNumber.length - 4)}`,
        },
      ];
    },
  },
};
</script>
        
<style lang="scss" scoped>
.execute-accounting {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.reasons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>