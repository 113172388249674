<template>
  <div class="subheading">
    <span class="field" v-for="field in filteredFields" :key="field.label">
      <p class="subtitle-1">{{ field.label }}:</p>
      <p class="subtitle-1">{{ field.data }}</p>
    </span>
  </div>
</template>
  
<script>
export default {
  props: {
    fields: {
      type: Array,
    },
  },
  computed: {
    filteredFields() {
      return this.fields.filter((fields) => fields.data);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.subheading {
  display: flex;
  gap: $qvo-spacing-24;
  align-items: center;
  .field {
    display: flex;
    gap: $qvo-spacing-8;
    p:first-child {
      color: $qvo-color-grey-600;
    }
  }
}
</style>