<template>
  <section class="accountholder-select">
    <h6>Account Select</h6>
    <dataTable
      :value="selectedAccount"
      :headers="accountSelectHeaders"
      :items="accounts || []"
      item-key="AccountNumber"
      :itemsPerPage="8"
      :showInlineSearch="false"
      :loading="isLoadingAccounts"
      showSelect
      singleSelect
      @input="setSelectedAccount"
    >
      <template #item.AccountNumber="{ item }">
        <accountNumber :accountNumber="item.AccountNumber" />
      </template>
      <template #item.Balance="{ item }">
        {{ formatAmount(item.Balance) }}
      </template>
    </dataTable>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatAmount } from "@/util/format";
import accountNumber from "./accountNumber";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";

export default {
  components: {
    accountNumber,
    dataTable,
  },
  data() {
    return {
      accountSelectHeaders: [
        {
          text: "Account",
          value: "Description",
        },
        {
          text: "Number",
          value: "AccountNumber",
        },
        {
          text: "Balance",
          align: "end",
          value: "Balance",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/create");
    },
    isLoadingAccounts() {
      return this.pendingCalls.includes("/claim/intake/retrieveAccounts");
    },
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
      customer: "getIntakeCustomer",
      accounts: "getAccounts",
      selectedAccount: "getSelectedAccount",
    }),
  },
  methods: {
    formatAmount(amount) {
      return formatAmount(amount);
    },
    setSelectedAccount(account) {
      this.$store.dispatch("setSelectedAccount", {
        account,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accountholder-select {
  height: calc(100% - 74px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>