<template>
    <v-sheet class="bottom-bar">
      <slot />
    </v-sheet>
  </template>
  
  <script>
  /**
   * @author Cameron Bulock <cameron.bulock@quavo.com>
   */
  
  export default {
    name: "BottomBar",
  };
  </script>
  
  <style lang="scss" scoped>
  .bottom-bar {
    box-shadow: $qvo-shadow-2;
    height: 74px;
    width: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
  </style>