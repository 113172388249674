<template>
  <div class="nav-buttons" :style="`width: calc(100% - ${panelWidth}px)`">
    <bottomBar>
      <qvoButton v-if="!cancelButtonHidden" text @click="handleCancel"
        >Cancel</qvoButton
      >
      <qvoButton
        v-if="!nextButtonHidden"
        :disabled="nextButtonDisabled"
        @click="handleNext"
        >{{ nextButtonLabel }}</qvoButton
      >
    </bottomBar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import bottomBar from "@/components/bottom-bar";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    bottomBar,
    qvoButton,
  },
  computed: {
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      cancelButtonHidden: "getIntakeNavigationCancelButtonHidden",
      nextButtonDisabled: "getIntakeNavigationNextButtonDisabled",
      nextButtonHidden: "getIntakeNavigationNextButtonHidden",
      nextButtonLabel: "getIntakeNavigationNextButtonLabel",
      panelWidth: "getTotalPanelWidth",
    }),
  },
  methods: {
    handleCancel() {
      this.$store.dispatch("cancelIntakeNav");
    },
    handleNext() {
      this.$store.dispatch("intakeNavigationNext");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-buttons {
  display: flex;
  justify-content: space-between;
  .actions {
    display: flex;
    gap: $qvo-spacing-default;
  }
}
</style>