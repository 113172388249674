<template>
  <transactionsDataTable
    singleSelect
    :transactionsList="transactionsList || []"
    :selectedTransactions="selectedTransactions"
    @retrieveTransactions="retrieveSimilarTransactions"
    @setSelectedTransactions="setSelectedSimilarTransactions"
  />
</template>

<script>
import { mapGetters } from "vuex";
import transactionsDataTable from "@/components/transactions-data-table";

export default {
  components: {
    transactionsDataTable,
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes(
        "/claim/intake/retrieveSimilarTransactions"
      );
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
      selectedTransactions: "getSimilarSelectedTransactions",
      transactionsList: "getSimilarTransactions",
    }),
  },
  methods: {
    addTransactions() {
      this.$store.dispatch("addSimilarTransactions", {
        ClaimId: this.selectedClaim.data.ClaimId,
        transactions: this.selectedTransactions,
      });
    },
    retrieveSimilarTransactions({ ClaimId, SearchStartDate, SearchEndDate, SearchAmount }) {
      this.$store.dispatch("retrieveSimilarTransactions", {
        ClaimId,
        SearchStartDate,
        SearchEndDate,
        SearchAmount,
      });
    },
    setSelectedSimilarTransactions(selected) {
      this.$store.dispatch("setSelectedSimilarTransactions", selected);
      this.$emit("selectedTransaction");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>