<template>
  <h5 class="split-header">
    {{ fields.join(" | ") }}
  </h5>
</template>
    
<script>
export default {
  props: {
    fields: {
      type: Array,
    },
  },
};
</script>
    
<style lang="scss" scoped>
</style>