<template>
  <section class="attachments-table">
    <div class="search">
      <textInput
        v-model="search"
        class="search"
        prependInnerIcon="mdi-magnify"
        placeholder="Search"
      />
      <uploadModal labelOverride="Add" @attachFile="addAttachments" />
    </div>
    <dataTable
      :headers="headers"
      :items="isLoading ? [] : attachments"
      itemKey="LinkReference"
      :loading="isLoading"
      ref="dataTable"
      :search="search"
      @click:row="viewAttachment"
    >
      <template #item.menu="{ item }">
        <v-menu offset-y zIndex="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="deleteAttachmentConfirmation(item)">
              <v-list-item-title> Delete Attachment </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </dataTable>
    <modal
      :value="deleteAttachmentModal.show"
      title="Are you sure you want to delete the attachment?"
      @click:outside="closeDeleteAttachmentModal"
    >
      <template #buttons>
        <qvoButton text @click="closeDeleteAttachmentModal">Cancel</qvoButton>
        <qvoButton @click="deleteAttachment">Yes</qvoButton>
      </template>
    </modal>
  </section>
</template>
    
<script>
/**
 * @fileoverview Attachments data table component
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import modal from "@/qux-common/qux22/components/molecules/modal";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import uploadModal from "@/views/create-claim/detail/required-docs-upload-modal";

export default {
  components: {
    dataTable,
    modal,
    textInput,
    qvoButton,
    uploadModal,
  },
  data() {
    return {
      deleteAttachmentModal: {show: false, LinkReference: null},
      /**
       * Data table headers
       */
      headers: [
        {
          text: "Name",
          align: "start",
          value: "Name",
        },
        {
          text: "Type",
          align: "start",
          value: "Category",
        },
        {
          text: "Created Date",
          align: "start",
          value: "displayDate",
        },
        {
          text: "Created By",
          align: "start",
          value: "CreateName",
        },
        {
          align: "end",
          value: "menu",
          sortable: false,
        },
      ],
      search: undefined,
    };
  },
  methods: {
    async addAttachments({ category, file, name }) {
      // for now UI only supports the one document, if we encounter the use case for multiple we can add support
      await this.$store.dispatch("addAttachments", {
        ClaimId: this.selectedClaim.data.ClaimId,
        files: [{ Category: category, file, Label: name }],
      });
      this.listAttachments();
    },
    closeDeleteAttachmentModal() {
      this.deleteAttachmentModal = { show: false, LinkReference: null };
    },
    async deleteAttachment() {
      this.deleteAttachmentModal.show = false;
      await this.$store.dispatch("deleteAttachment", {
        LinkReference: this.deleteAttachmentModal.LinkReference,
      });
      this.listAttachments();
    },
    deleteAttachmentConfirmation({ LinkReference }) {
      this.deleteAttachmentModal = { show: true, LinkReference };
    },
    listAttachments() {
      this.$store.dispatch("listAttachments", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    viewAttachment(item) {
      this.$emit("viewAttachment", item);
    },
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/attachments/list");
    },
    ...mapGetters({
      attachments: "getAttachments",
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
    }),
  },
  created() {
    this.listAttachments();
  },
};
</script>
    
<style lang="scss" scoped>
.attachments-table {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  .search {
    display: flex;
    justify-content: space-between;
    .input {
      width: 446px;
    }
    .send-message {
      align-self: center;
    }
  }
  .v-data-table {
    &::v-deep {
      tr {
        cursor: pointer;
      }
    }
  }
}
</style>