<template>
  <div class="tasks">
    <pageTabHeader>
      <h5>Tasks</h5>
    </pageTabHeader>
    <tabContent>
      <list @openTask="openTask" />
    </tabContent>
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";

import pageTabHeader from "@/components/page-tab-header";
import tabContent from "@/components/tab-content";

import list from "./list";

export default {
  components: {
    pageTabHeader,
    tabContent,
    list,
  },
  methods: {
    openTask(task) {
      const { ClaimId, TaskLabel } = task;
      this.$store.dispatch("setOpenTask", {
        ClaimId,
        TaskLabel,
      });
      this.$store.dispatch("clearTasksDetail");
    },
    retrieveTaskSummary() {
      this.$store.dispatch("retrieveTaskSummary", {
        ClientId: this.activeClientId,
      });
    },
  },
  computed: {
    ...mapGetters({
      activeClientId: "getActiveClientId",
      disputeDetailsIsShowing: "getDisputeDetailsIsShowing",
    }),
  },
  mounted() {
    this.retrieveTaskSummary();
  },
};
</script>
    
<style lang="scss" scoped>
.tasks {
  height: 100%;
  padding-bottom: 22px;
}

.content {
  padding: 40px;
}

h4 {
  margin-bottom: $qvo-spacing-8;
}
</style>