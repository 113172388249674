<template>
  <dropdown
    :items="formattedAnswers"
    :rules="isOptional ? [] : [rules]"
    :required="!isOptional"
    :value="value"
    @change="[setAnswer($event)]"
    :disabled="disabled"
    autocomplete
  />
</template>

<script>
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";

export default {
  components: {
    dropdown,
  },
  props: {
    /**
     * Selected answers
     */
    answers: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Available answers to select
     */
    answerList: {
      type: Array,
    },
    displayText: {
      type: String,
    },
    type: {
      type: String,
    },
    value: {
      type: String,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: (v) => !!v || "Selection is required",
    },
  },
  methods: {
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      this.$emit("setAnswer", answer);
    },
  },
  computed: {
    formattedAnswers() {
      if (this.displayText === "Country") {
        return this.answerList.map((answer) => ({
          text: answer.Identifier,
          value: answer.Identifier,
          countryCode: answer.DisplayText,
        }));
      }
      return this.answerList.map((answer) => ({
        text: answer.DisplayText,
        value: answer.Identifier,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.country-item {
  display: grid;
  grid-template-columns: 2.5em 1fr;
  column-gap: 1em;
  .v-chip {
    justify-content: center;
  }
}
</style>