import { render, staticRenderFns } from "./recovery-details.vue?vue&type=template&id=63cec661&scoped=true"
import script from "./recovery-details.vue?vue&type=script&lang=js"
export * from "./recovery-details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63cec661",
  null
  
)

export default component.exports