<template>
  <div>
    <modal
      :value="isOpen"
      v-bind="$attrs"
      v-on="$listeners"
      title="Transaction Details"
      @closeModal="closeModal"
    >
      <div class="details">
        <div
          v-for="(value, key) in transactionDetails"
          class="detail"
          :key="key"
        >
          <p class="subtitle-1">{{ lodash.startCase(key) }}</p>
          <div v-if="lodash.isObject(value)" class="additional-details">
            <div v-for="(subvalue, subkey) in value" :key="subkey">
              <p class="subtitle-1">{{ lodash.startCase(subkey) }}</p>
              <p class="body-1">
                {{ displayValue({ key: subkey, value: subvalue }) }}
              </p>
            </div>
          </div>
          <p class="body-1" v-else>{{ displayValue({ key, value }) }}</p>
        </div>
      </div>
      <template #buttons>
        <qvoButton @click="closeModal">Close</qvoButton>
      </template>
    </modal>
    <fullscreenLoader v-if="isLoading" />
  </div>
</template>
    
<script>
/**
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import { formatAmount, formatDate, formatDateTime } from "@/util/format";
import fullscreenLoader from "@/qux-common/qux22/components/molecules/fullscreen-loader";
import modal from "@/components/modal";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: { fullscreenLoader, modal, qvoButton },
  props: {
    isOpen: {
      type: Boolean,
    },
    ClaimId: {
      type: String,
    },
    DisputeId: {
      type: String,
    },
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/transactions/details/retrieve");
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
      transactionDetails: "getTransactionDetails",
    }),
  },
  methods: {
    retrieveTransactionDetails() {
      this.$store.dispatch("retrieveTransactionDetails", {
        ClaimId: this.ClaimId,
        DisputeId: this.DisputeId,
      });
    },
    displayValue({ key, value }) {
      switch (key) {
        case "AccountNumber":
        case "CardId":
        case "CardNumber":
        case "PreviousCardNumber":
          return this.maskAccountNumber(value);
        case "Amount":
        case "AmountPosted":
        case "AuthorizationAmount":
        case "DisputedAmount":
          return this.formatAmount(value);
        case "Date":
        case "DateStatementBegins":
        case "PostingDate":
        case "TransactionDate":
          return this.formatDate(value);
        case "PostingDateTime":
        case "TransactionDateTime":
          return this.formatDateTime(value);
      }
      return value;
    },
    maskAccountNumber(number) {
      // Expose the first 6 digits and the last 4 digits
      const regex = new RegExp("(?<=^.{6})(.*)(?=\\d{4})");

      const convert = (match) => {
        return "*".repeat(match.length);
      };

      return number.replace(regex, convert);
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatDateTime(datetime) {
      return formatDateTime(datetime);
    },
    formatAmount(amount) {
     return formatAmount(amount);
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  watch: {
    isOpen(value) {
      if (value) this.retrieveTransactionDetails();
    },
  },
};
</script>
    
<style lang="scss" scoped>
.details {
  --columns: 1;
  max-width: 100vw;
  @media only screen and (min-width: 500px) {
    --columns: 2;
    max-width: 70vw;
  }
  @media only screen and (min-width: 900px) {
    --columns: 3;
    max-width: 50vw;
  }

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: 2rem;
  .detail {
    margin: 1rem;
    padding: 1rem;
    width: 100%;
    overflow: auto;
    .additional-details {
      margin-top: 1rem;
      padding-left: 1rem;
      border-left: 4px solid $qvo-color-grey-300;
    }
  }
}
</style>