import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat);

const formatAmount = (amount, { currency = 'USD', locale = 'en-US' } = {}) => {
    const currencyObject = new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
    });
    return currencyObject.format(amount);
};

const formatBytes = (bytes, { decimals = 2 } = {}) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const formatDate = (date, { inputFormat = "YYYYMMDD", outputFormat = 'L' } = {}) => {
    if (!date) return null;
    return dayjs(date, inputFormat).format(outputFormat);
}
const formatDateTime = (datetime, { inputFormat = undefined, outputFormat = 'L LTS' } = {}) => {
    if (!datetime) return null;
    return dayjs(datetime, inputFormat).format(outputFormat);
}

export { formatAmount, formatBytes, formatDate, formatDateTime }