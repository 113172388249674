<template>
  <div>
    <baseInput
      :question="question"
      :answers="answers"
      :displayReasonPrefixText="displayReasonPrefixText"
      @setAnswer="setAnswer"
      @updateFollowupQuestions="getFollowUpQuestions"
      :disabled="disabled"
      :value="value"
    />
    <div
      v-for="followupQuestion in followUpQuestions"
      :key="followupQuestion.Identifier"
    >
      <inputControls
        :question="followupQuestion"
        :answers="answers"
        @setAnswer="setAnswer"
        @unsetAnswer="(args) => $emit('unsetAnswer', args)"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import baseInput from "./base-input";

export default {
  components: {
    inputControls: () => import("."),
    baseInput,
  },
  name: "input-controls",
  data() {
    return {
      selectedAnswer: "",
      followUpQuestions: undefined,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
    },
    displayReasonPrefixText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value() {
      return (
        (this.answers && this.answers[this.question.Identifier]) || undefined
      );
    },
  },
  methods: {
    getFollowUpQuestions() {
      let followUpQuestions = [];

      const isQuestionAnswered =
        this.answers && this.question.Identifier in this.answers;
      if (isQuestionAnswered && this.question.AnswerList) {
        const answerDetails = this.question.AnswerList.find((answer) => {
          if (answer.Identifier === this.answers[this.question.Identifier])
            return answer;
        });
        if (answerDetails && answerDetails.QuestionList)
          followUpQuestions = [
            ...followUpQuestions,
            ...answerDetails.QuestionList,
          ];
      }

      if (this.question.QuestionList) {
        // some questions have top level questions themselves
        followUpQuestions = [
          ...followUpQuestions,
          ...this.question.QuestionList,
        ];
      }
      this.followUpQuestions = followUpQuestions.length
        ? followUpQuestions
        : undefined;
    },
    setAnswer(args) {
      this.selectedAnswer = args.answer;
      this.$emit("setAnswer", args);
      this.getFollowUpQuestions();
    },
  },
  mounted() {
    // need to make sure this is happening on mount as questions could already be answered
    this.getFollowUpQuestions();
  },
  watch: {
    question() {
      // need to refresh followup questions anytime the question changes
      this.getFollowUpQuestions();
    },
  },
  beforeDestroy() {
    this.$emit("unsetAnswer", {
      questionId: this.question.Identifier,
    });
  },
};
</script>

<style lang="scss" scoped>
</style>