<template>
  <div class="transactions-wrapper">
    <div
      v-for="transaction in uniqueMerchants"
      :key="transaction.TransactionId"
      class="transactions"
    >
      <p>
        {{ transaction.Description }}
      </p>
      <radioButtons
        :answerList="[
          { Identifier: 'Yes', DisplayText: 'Yes' },
          { Identifier: 'No', DisplayText: 'No' },
        ]"
        type="YesNo"
        value=""
        :rules="radioValidation"
        @setAnswer="
          [
            setAnswer('RecognizesMerchant'),
            setRecognizedTransactions($event, transaction.TransactionId),
          ]
        "
        :disabled="disableTransactions"
      />
    </div>
    <checkboxes
      v-if="uniqueMerchants.length > 1"
      :answerList="[
        {
          Identifier: 'DoesNotRecognize',
          DisplayText: 'I have not done business with any of these merchants',
        },
      ]"
      @setAnswer="[setAnswer($event[0]), checkboxUpdated(!!$event[0])]"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import checkboxes from "../checkboxes";
import radioButtons from "../radio-buttons";

export default {
  components: { checkboxes, radioButtons },
  data() {
    return {
      disableTransactions: false,
      recognizedTransactions: [],
    };
  },
  computed: {
    uniqueMerchants() {
      return this.selectedTransactions.filter(
        (transaction, index, array) =>
          array.findIndex((t) => t.Description === transaction.Description) ===
          index
      );
    },
    radioValidation() {
      return this.disableTransactions
        ? () => true
        : (v) => !!v || "Selection is required";
    },
    ...mapGetters({
      selectedTransactions: "getSelectedTransactions",
    }),
  },
  methods: {
    setAnswer(answer) {
      this.$emit(
        "setSelected",
        answer === "DoesNotRecognize"
          ? "DoesNotRecognize"
          : "RecognizesMerchant"
      );
    },
    setRecognizedTransactions(answer, id) {
      if (answer === "Yes") {
        this.recognizedTransactions.push(id);
      } else {
        this.recognizedTransactions = this.recognizedTransactions.filter(
          (transaction) => id !== transaction
        );
      }
      this.$store.dispatch(
        "setRecognizedTransactions",
        this.recognizedTransactions
      );
    },
    checkboxUpdated(value) {
      this.disableTransactions = value;
    },
  },
  beforeDestroy() {
    this.$store.dispatch("setRecognizedTransactions", []);
  },
};
</script>

<style lang="scss" scoped>
.transactions-wrapper {
  width: fit-content;
}
.transactions {
  display: grid;
  grid-template-columns: [name] 1fr [answers] 1fr;
  gap: 1em;
  align-items: center;
}
</style>