<template>
  <modal :value="displayModal" title="Are you sure you want to create a new claim?" @click:outside="closeModal">
    <template #buttons>
      <qvoButton text @click="closeModal">Cancel</qvoButton>
      <qvoButton @click="restartIntake">Yes</qvoButton>
    </template>
    <div class="content">
        <p class="body-1">You will lose all of your previous work</p>
    </div>
  </modal>
</template>
    
<script>
import { mapGetters } from "vuex";
import modal from "@/qux-common/qux22/components/molecules/modal";
import qvoButton from "@/qux-common/qux22/components/molecules/button";


export default {
  components: {
    modal,
    qvoButton,
  },
  data() {
    return {
      isFormValid: false,
      details: undefined,
      title: undefined,
    };
  },
  methods: {
    restartIntake() {
      this.$store.dispatch("startIntake");
    },
    closeModal() {
        this.$store.dispatch("displayRestartClaimModal", false);
    },
  },
  computed: {
    ...mapGetters({
      displayModal: "getIntakeDisplayRestartClaimModal",
    }),
  },
};
</script>
    
<style lang="scss" scoped>
</style>