<template>
  <v-tabs
    v-bind="$attrs"
    v-on="$listeners"
    @change="(event) => $emit('input', event)"
    show-arrows
  >
    <v-tab
      v-for="item in items"
      :key="item.name"
      :href="item.href"
      :disabled="disabled"
    >
      <v-tooltip
        right
        max-width="20em"
        v-if="item.leftIcon && item.leftIconTooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="left-icon">
            {{ item.leftIcon }}
          </v-icon>
        </template>
        {{ item.leftIconTooltip }}
      </v-tooltip>
      <v-icon v-else-if="item.leftIcon" class="left-icon">
        {{ item.leftIcon }}
      </v-icon>
      <p class="subtitle-2">{{ item.name }}</p>
    </v-tab>
    <slot />
  </v-tabs>
</template>

<script>
/**
 * @fileoverview Common component for tabs
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Tabs",
  props: {
    items: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs {
  &::v-deep {
    .v-tabs-slider {
      background: $qvo-color-blue-400;
    }
  }
}
.subtitle-2 {
  color: $qvo-color-grey-600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left-icon {
  margin-right: $qvo-spacing-8;
}
.v-tab--active .subtitle-2 {
  color: $qvo-color-blue-400;
}

.v-tooltip__content {
  color: $qvo-color-grey-000;
}
</style>