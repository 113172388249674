<template>
    <div class="content" ref="content" id="tab-content">
      <slot />
    </div>
  </template>
  
  <script>
  export default {
    name: "TabContent",
    props: {
      resetScroll: {
        type: String,
      },
    },
    methods: {
      scrollToTop() {
        this.$refs.content.scrollTo(0, 0);
      },
    },
    watch: {
      resetScroll() {
        this.scrollToTop();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .content {
    overflow: auto;
    height: 100%;
    scroll-behavior: smooth;
  }
  </style>