<template>
  <section id="account-select-data-table">
    <dataTable
      v-if="accountList.length > 0 || isLoading"
      v-model="selected"
      :headers="headers"
      :items="isLoading ? [] : accountList"
      itemKey="AccountNumber"
      :itemsPerPage="8"
      show-select
      single-select
      :loading="isLoading"
    >
      <template #item.Balance="{ item }">
        {{ formatAmount(item.Balance) }}
      </template>
    </dataTable>
    <div v-else class="empty-list">
      <div class="empty-list-content">
        <img class="empty-state-graphic" src="@/assets/imgs/empty-box.svg" />
        <div>
          <p class="copy-large">We didn’t find any matching accounts.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/**
 * @fileoverview Account search data table component
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import { formatAmount } from "@/util/format";

export default {
  props: {
    accountList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      /**
       * Data table headers
       */
      headers: [
        {
          text: "Account Name",
          align: "start",
          value: "Description",
        },
        {
          text: "Account #",
          align: "start",
          value: "AccountNumber",
        },
        {
          text: "Balance",
          align: "end",
          value: "Balance",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/retrieveOtherAccounts");
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
      selectedOtherAccount: "getSelectedOtherAccount",
    }),
  },
  methods: {
    retrieveAccounts() {
      this.$emit("retrieveAccounts", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    setSelectedAccount() {
      this.$emit("setSelectedAccount", this.selected[0]);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
  },
  mounted() {
    if (this.$route.query.step === "3") {
      this.retrieveAccounts();
    }
  },
  watch: {
    "$route.query.step"(step) {
      if (step === "3") this.retrieveAccounts();
    },
    accountList() {
      if (this.selectedOtherAccount) {
        this.accountList.forEach((currAccount) => {
          if (this.selectedOtherAccount === currAccount.AccountNumber) {
            this.selected.push(currAccount);
          }
        });
      }
    },
    selected() {
      this.setSelectedAccount();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--checkbox {
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss">
@import "@/design/components/empty-table.scss";
</style>