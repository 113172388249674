<template>
    <div
      class="file-display"
      :class="
        fileHeader && fileHeader === 'data:application/pdf;base64' && 'is-pdf'
      "
    >
      <object v-if="fileSource">
        <embed :src="fileSource" />
      </object>
    </div>
  </template>
      
  <script>
  export default {
    props: {
      fileSource: {
        type: String,
      },
      fileHeader: {
        type: String,
      },
    },
  };
  </script>
      
  <style lang="scss" scoped>
  .file-display {
    position: relative;
    width: fit-content;
    height: fit-content;
    &.is-pdf {
      height: 100%;
      width: 100%;
      object {
        height: 100%;
        width: 100%;
        embed {
          height: 100%;
          width: 100%;
        }
      }
    }
    object {
      display: flex;
      border: 2px dashed $qvo-color-grey-400;
      border-radius: 8px;
      padding: $qvo-spacing-8;
      margin: 0 auto;
      width: fit-content;
      embed {
        max-width: 100%;
      }
    }
  }
  </style>