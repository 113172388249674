<template>
  <v-overlay />
</template>

<script>
/**
 * @fileoverview Overlay component
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Overlay",
};
</script>

<style lang="scss" scoped>
.v-overlay {
  z-index: 200 !important;
  &::v-deep {
    .v-overlay__scrim {
      background-color: #000 !important;
      opacity: 0.25 !important;
    }
  }
}
</style>