<template>
  <div>
    <div class="title">
      <backButton @click="close" />
      <h5>{{ title }}</h5>
    </div>

    <div class="heading">
      <splitHeader :fields="disputeHeader" />
      <tag v-if="dispute && dispute.DisputeStatus">{{
        dispute.DisputeStatus
      }}</tag>
    </div>
    <subHeaderFields :fields="[{ label: 'Dispute ID', data: truncatedId }]" />
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";
import { formatAmount } from "@/util/format";

import backButton from "@/components/back-button";
import splitHeader from "@/components/split-header";
import subHeaderFields from "@/components/sub-header-fields";
import tag from "@/qux-common/qux22/components/molecules/tag";

export default {
  components: {
    backButton,
    splitHeader,
    subHeaderFields,
    tag,
  },
  props: {
    claimId: {
      type: String,
    },
    disputeId: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  computed: {
    claim() {
      if (!this.claims) return;
      return this.claims[this.claimId] || {};
    },
    dispute() {
      if (!this.claim || !this.claim.transactions) return;
      return this.claim.transactions.find(
        (transaction) => transaction.DisputeId === this.disputeId
      );
    },
    disputeHeader() {
      let headings = [];
      if (this.dispute && this.dispute.Description)
        headings.push(this.dispute.Description);
      if (this.dispute && this.dispute.DisputedAmount)
        headings.push(this.formatAmount(this.dispute.DisputedAmount));
      return headings;
    },
    truncatedId() {
      return `X${this.disputeId.substring(this.disputeId.length - 5)}`;
    },
    ...mapGetters({
      claims: "getClaims",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
  },
};
</script>
    
  <style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  gap: $qvo-spacing-24;
  margin-bottom: $qvo-spacing-16;
}

.heading {
  display: flex;
  gap: $qvo-spacing-8;
  align-items: center;
  margin-bottom: 12px;
}

.subheading {
  margin-bottom: $qvo-spacing-24;
}
</style>