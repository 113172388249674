import { render, staticRenderFns } from "./deny-commentary-modal.vue?vue&type=template&id=9215a0f2&scoped=true"
import script from "./deny-commentary-modal.vue?vue&type=script&lang=js"
export * from "./deny-commentary-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9215a0f2",
  null
  
)

export default component.exports