<template>
  <div class="pagination">
    <p class="body-1">Items per page</p>
    <dropdown
      v-model="localPerPage"
      :items="perPageItems"
      @input="(v) => $emit('perPage', v)"
    />
    <p class="body-1 page-data">
      {{ itemStart }}-{{ itemEnd }} of {{ itemCount }}
    </p>
    <v-pagination
      v-model="localPage"
      v-bind="$attrs"
      v-on="$listeners"
      total-visible="5"
      :length="pages"
    >
    </v-pagination>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for pagination
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import dropdown from "./dropdown";

export default {
  name: "Pagination",
  components: { dropdown },
  data() {
    return {
      localPage: this.page,
      localPerPage: this.itemsPerPage,
      perPageItems: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };
  },
  props: {
    itemCount: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    updatePage(page) {
      this.$emit("input", page);
      this.localPage = page;
    },
  },
  computed: {
    pages() {
      const perPage =
        this.localPerPage && this.localPerPage > 0 ? this.localPerPage : 10;
      return Math.ceil(this.itemCount / perPage);
    },
    itemStart() {
      return (this.localPage - 1) * this.localPerPage + 1;
    },
    itemEnd() {
      return Math.min(this.localPage * this.localPerPage, this.itemCount);
    },
  },
  watch: {
    localPerPage() {
      if (this.itemStart > this.itemCount) this.updatePage(1);
    },
    page(page) {
      if (page) this.localPage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: 1em;
  .body-1 {
    color: black;
  }
  .page-data {
    margin: 0 1em;
  }
  &::v-deep {
    .dropdown {
      width: 100px;
    }
    .v-icon {
      color: $qvo-color-grey-900;
    }
    .v-list {
      padding: 0;
    }
    .v-pagination {
      gap: 4px;
      button,
      .v-pagination__more {
        min-width: 21px;
        height: 25px;
        margin: 0;
        padding: 0 4px;
        box-shadow: none;
        font-weight: 500;
        color: $qvo-color-grey-900;
        &.v-pagination__item--active {
          border: 1px solid $qvo-color-blue-400;
          color: $qvo-color-blue-400;
        }
      }
    }
  }
}
</style>