var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"initial-investigation"},[_c('actionSelectionBox',[_c('div',{staticClass:"first-row-inputs"},[_c('div',{staticClass:"action-select"},[_c('p',{staticClass:"subtitle-2"},[_vm._v("Select an action for the following disputes")]),_c('radioGroup',{attrs:{"required":""},on:{"input":_vm.setActionForDisputes},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}},_vm._l((_vm.actions),function(action){return _c('radio',{key:action,attrs:{"label":action,"value":action}})}),1)],1),(_vm.selectedAction === 'Deny Claim')?_c('dropdown',{attrs:{"placeholder":"Select denial reason","label":"Please select an appropriate denial reason from the following list","items":_vm.denyReasonsDropdownItems},on:{"change":_vm.updateDenyReason},model:{value:(_vm.selectedDenyReason),callback:function ($$v) {_vm.selectedDenyReason=$$v},expression:"selectedDenyReason"}}):_vm._e(),(_vm.justificationRequired)?_c('dropdown',{attrs:{"placeholder":`Select ${
          _vm.justificationRequiredCount === 1
            ? 'justification'
            : 'justifications'
        }`,"label":`Please select ${_vm.justificationRequiredCount} ${
          _vm.justificationRequiredCount === 1
            ? 'justification'
            : 'justifications'
        } from the following list`,"items":_vm.denyJustificationsDropdownItems,"multiple":""},on:{"change":_vm.updateDenyJustification},model:{value:(_vm.selectedJustifications),callback:function ($$v) {_vm.selectedJustifications=$$v},expression:"selectedJustifications"}}):_vm._e()],1),(_vm.selectedAction === 'Deny Claim' && _vm.isDenialCommentaryRequired)?_c('textInput',{attrs:{"multiline":"","label":"Please provide commentary to support your denial decision."},on:{"change":_vm.updateDenyCommentary}}):_vm._e()],1),_c('h6',[_vm._v("Disputes")]),_c('disputesTable',{attrs:{"actions":_vm.actions,"claimId":_vm.claimId,"columns":_vm.tableColumns,"items":_vm.tasks,"isLoading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }