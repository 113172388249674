<template>
  <div>
    <modal
      :value="isOpen"
      v-bind="$attrs"
      v-on="$listeners"
      title="Message"
      @closeModal="closeModal"
    >
      <v-form>
        <textInput
            class="subject"
            v-model="Subject"
            label="Subject"
        />
        <textInput
            class="message"
            v-model="Message"
            label="Message"
            multiline
        />
      </v-form>
      <template class= "bottom" #buttons>
        <qvoButton text @click="closeModal">Cancel</qvoButton>
        <qvoButton @click="sendMessage">Send</qvoButton>
      </template>
    </modal>
  </div>
</template>
    
<script>
/**
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import modal from "@/components/modal";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: { 
    modal,
    qvoButton,
    textInput
},
  props: {
    isOpen: {
      type: Boolean,
    },
    ClaimId: {
      type: String,
    }
  },
  data() {
    return {
      Subject: "",
      Message: ""
    };
  },
  computed: {
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    closeModal() {
      this.Subject = undefined;
      this.Message = undefined;
      this.$emit("closeModal");
    },
    sendMessage() {
      this.$store.dispatch("sendMessage", {
        Subject: this.Subject,
        Message: this.Message,
        ClaimId: this.selectedClaim.data.ClaimId
      });
      if(this.Subject && this.Message) {
        this.closeModal();
        this.$store.dispatch("retrieveCorrespondenceList", this.selectedClaim.data.ClaimId);
      }
    }
  },
  watch: {
  },
};
</script>
    
<style lang="scss" scoped>

.subject {
  padding-bottom: $qvo-spacing-24;
}
.message {
  width: 742px;
}
.bottom {
  position: absolute;
}
</style>