<template>
  <div class="dispute-details">
    <appliedMerchantCredits
      v-if="appliedMerchantCredits && appliedMerchantCredits.show"
      :claimId="claimId"
      :disputeId="appliedMerchantCredits && appliedMerchantCredits.disputeId"
      @close="closeAppliedMerchantCredits"
    />
    <recoveryDetails
      v-else-if="recoveryDetails && recoveryDetails.show"
      :claimId="claimId"
      :disputeId="recoveryDetails && recoveryDetails.disputeId"
      @close="closeRecoveryDetails"
    />
    <template v-else>
      <slot />
    </template>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import appliedMerchantCredits from "./applied-merchant-credits";
import recoveryDetails from "./recovery-details";

export default {
  components: {
    appliedMerchantCredits,
    recoveryDetails,
  },
  props: {
    claimId: {
      type: String,
    },
  },
  methods: {
    closeAppliedMerchantCredits() {
      this.$store.dispatch("showAppliedMerchantCredits", {
        DisputeId: null,
      });
    },
    closeRecoveryDetails() {
      this.$store.dispatch("showRecoveryDetails", {
        DisputeId: null,
      });
    },
  },
  computed: {
    ...mapGetters({
      appliedMerchantCredits: "getAppliedMerchantCreditsView",
      recoveryDetails: "getRecoveryDetailsView",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>