<template>
  <!-- Currently the questionnaire type is just an empty wrapper for additional questions -->
  <div />
</template>
    
<script>
export default {
  created() {
    this.$emit("created");
  },
};
</script>