<template>
  <section>
    <h6 v-if="transactionsStatus.transactionsListDisplay === 'optional'">
      If applicable, select the transactions you want to dispute below or
      continue:
    </h6>
    <h6 v-else>Select the transactions you want to dispute below:</h6>
    <transactionsDataTable
      :transactionsList="transactionsList || []"
      :selectedTransactions="selectedTransactions"
      @setSelectedTransactions="setSelectedTransactions"
      @retrieveTransactions="retrieveTransactions"
    />
  </section>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { mapGetters } from "vuex";
import transactionsDataTable from "@/components/transactions-data-table";

dayjs.extend(localizedFormat);

export default {
  components: {
    transactionsDataTable,
  },
  props: {
    shouldRefreshTxns: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    oneYearAgo() {
      return dayjs().subtract(12, "months").format("YYYY-MM-DD");
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/addTransactions");
    },
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
      selectedTransactions: "getSelectedTransactions",
      transactionsList: "getTransactions",
      transactionsStatus: "getTransactionsStatus",
    }),
  },
  methods: {
    retrieveTransactions({ ClaimId, SearchStartDate, SearchEndDate }) {
      this.$store.dispatch("retrieveTransactions", {
        ClaimId,
        SearchStartDate,
        SearchEndDate,
      });
      this.$emit("setShouldRefreshTxns", false);
    },
    setSelectedTransactions(selected) {
      this.$store.dispatch("setSelectedTransactions", selected);
    },
  },
  mounted() {
    if (this.currentStep.parentStep === 3 && this.shouldRefreshTxns) {
      this.retrieveTransactions({
        ClaimId: this.selectedClaim.data.ClaimId,
        SearchStartDate: this.oneYearAgo.replaceAll("-", ""),
        SearchEndDate: this.today.replaceAll("-", ""),
      });
    }
  },
  watch: {
    "currentStep.parentStep"() {
      if (this.currentStep.parentStep === 3 && this.shouldRefreshTxns) {
        this.retrieveTransactions({
          ClaimId: this.selectedClaim.data.ClaimId,
          SearchStartDate: this.oneYearAgo.replaceAll("-", ""),
          SearchEndDate: this.today.replaceAll("-", ""),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
</style>