<template>
  <section class="confirmation">
    <div v-if="confirmationMessage" class="thank-you">
      <div class="thumbs-up">
        <v-icon size="96">mdi-check-circle-outline</v-icon>
      </div>
      <div class="message">
        <h5>Thank you, you're all set!</h5>
        <p subtitle-1>Confirmation #: {{ selectedClaim.data.ClaimId }}</p>
      </div>
    </div>
    <div class="message-body" v-html="confirmationMessage" />
    <div class="confirmation-download">
      <qvoButton
        v-if="confirmationDocument.data"
        class="secondary"
        :href="pdfDownload"
        :download="confirmationDocument.name"
      >
        Save/Print PDF
      </qvoButton>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: { qvoButton },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/retrieveConfirmation");
    },
    pdfDownload() {
      return `data:application/octet-stream;charset=utf-8;base64,${this.confirmationDocument.data}`;
    },
    ...mapGetters({
      confirmationDocument: "getConfirmationDocument",
      confirmationMessage: "getConfirmationMessage",
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    retrieveConfirmation() {
      this.$store.dispatch("retrieveConfirmation", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
  },
  mounted() {
    if (this.currentStep.name === "confirmation") {
      this.retrieveConfirmation();
    }
  },
  watch: {
    "currentStep.name"() {
      if (this.currentStep.name === "confirmation")
        this.retrieveConfirmation();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}
.thank-you {
  margin-top: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: $qvo-shadow-2;
  padding: 24px 48px;
  width: fit-content;
  gap: $qvo-spacing-16;
  .thumbs-up {
    display: flex;
    align-items: center;
    justify-content: center;
    .v-icon {
      color: $qvo-color-status-green;
    }
  }
  .message {
    p {
      margin-top: $qvo-spacing-8;
      color: $qvo-color-grey-600;
    }
  }
}

.message-body {
  width: 100%;
  &::v-deep {
    .qvoBodyContent {
      width: 100%;
    }
    p:first-child {
      // h6 style
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5;
    }
    p {
      // subtitle-1 style
      font-family: $qvo-sansserif-font-family !important;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0 !important;
      strong {
        // subtitle-2 style
        font-family: $qvo-sansserif-font-family !important;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0 !important;
      }
    }
    ul {
      list-style-type: none !important;
      margin: 0;
      padding: 0;
      li {
        color: $qvo-color-grey-600;
        // body-2 style
        font-family: $qvo-sansserif-font-family;
        font-size: 0.875rem;
        font-weight: 350;
        line-height: 1.4;
        letter-spacing: 0 !important;
      }
    }
    .qvo-customercontent-table {
      margin: $qvo-spacing-24 0;
      width: 100%;
      border: 1px solid $qvo-color-grey-200;
      border-radius: 8px;
      border-spacing: 0;
      overflow: hidden;
      thead {
        border-radius: 8px 8px 0 0;
        background: $qvo-color-blue-100;
        tr {
          border-color: $qvo-color-grey-200;
          th {
            text-align: left;
            padding: 16px;
            white-space: nowrap;
            // subtitle-1 styles
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }
      tbody {
        tr > td {
          padding: 16px;
          // subtitle-2 styles
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>