<template>
  <div class="read-inbound-correspondence">
    <actionSelectionBox>
      <div class="action-select">
        <p class="subtitle-2">Select an action</p>
        <radioGroup v-model="selectedAction" @input="setActionForTasks" required>
          <radio
            v-for="action in actions"
            :key="action"
            :label="action"
            :value="action"
          />
        </radioGroup>
      </div>
    </actionSelectionBox>
    <dataTable
      v-model="selectedDisputes"
      :headers="tableColumns"
      :items="tasks"
      item-key="TaskId"
      :loading="isLoading"
      :showSelect="showSelect"
      :search="search"
    >
      <template #item.Detail.ReceivedDateTime="{ item }">
        {{ formatDateTime(item.Detail.ReceivedDateTime) }}
      </template>
      <template #item.Detail.LinkReference="{ item }">
        <qvoButton small @click="downloadMessage(item.Detail.LinkReference)">Download Message</qvoButton>
      </template>
    </dataTable>
  </div>
</template>
            
<script>
import { mapGetters } from "vuex";
import { formatDateTime } from "@/util/format";

import actionSelectionBox from "./action-selection-box";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    actionSelectionBox,
    dataTable,
    radio,
    radioGroup,
    qvoButton,
  },
  props: {
    actions: {
      type: Array,
    },
    claimId: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAction: undefined,
      tableColumns: [
        {
          text: "Received",
          value: "Detail.ReceivedDateTime",
        },
        {
          text: "Subject",
          value: "Detail.Subject",
        },
        {
          value: "Detail.LinkReference",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    messageDownload() {
      return `data:application/octet-stream;charset=utf-8;base64,${this.attachment.DocumentStream}`;
    },
    ...mapGetters({
      attachment: "getAttachment",
    }),
  },
  methods: {
    downloadMessage(corrKey) {
      this.$store.dispatch("retrieveAttachment", corrKey).then(() => {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.messageDownload;
        downloadLink.download = this.attachment.AttachmentName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    },
    formatDateTime(datetime) {
      return formatDateTime(datetime.replace("GMT", "-0000"), {
        inputFormat: "YYYYMMDDHHmmss.SSS ZZ",
      });
    },
    async setActionForTasks(action) {
      this.tasks.forEach(async (task) => {
        await this.$store.dispatch("setTaskAction", {
          action,
          TaskId: task.TaskId,
        });
      });
    },
  },
};
</script>
            
<style lang="scss" scoped>
.read-inbound-correspondence {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.action-select {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>