var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactions-wrapper"},[_vm._l((_vm.uniqueMerchants),function(transaction){return _c('div',{key:transaction.TransactionId,staticClass:"transactions"},[_c('p',[_vm._v(" "+_vm._s(transaction.Description)+" ")]),_c('radioButtons',{attrs:{"answerList":[
        { Identifier: 'Yes', DisplayText: 'Yes' },
        { Identifier: 'No', DisplayText: 'No' },
      ],"type":"YesNo","value":"","rules":_vm.radioValidation,"disabled":_vm.disableTransactions},on:{"setAnswer":function($event){[
          _vm.setAnswer('RecognizesMerchant'),
          _vm.setRecognizedTransactions($event, transaction.TransactionId),
        ]}}})],1)}),(_vm.uniqueMerchants.length > 1)?_c('checkboxes',{attrs:{"answerList":[
      {
        Identifier: 'DoesNotRecognize',
        DisplayText: 'I have not done business with any of these merchants',
      },
    ]},on:{"setAnswer":function($event){[_vm.setAnswer($event[0]), _vm.checkboxUpdated(!!$event[0])]}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }