<template>
  <div>
    <disputeHeader
      :claimId="claimId"
      :disputeId="disputeId"
      title="Recovery"
      @close="close"
    />

    <dataTable
      :headers="columns"
      :items="isLoading ? [] : recoveryDetails"
      itemKey="DisputeAmount"
      :itemsPerPage="8"
      :loading="isLoading"
    >
      <template #item.Date="{ item }">
        {{ formatDate(item.Date) }}
      </template>
      <template #item.DisputeAmount="{ item }">
        {{ formatAmount(item.DisputeAmount) }}
      </template>
    </dataTable>
  </div>
</template>
    
  <script>
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import disputeHeader from "./dispute-header";

export default {
  components: {
    dataTable,
    disputeHeader,
  },
  props: {
    claimId: {
      type: String,
    },
    disputeId: {
      type: String,
    },
  },
  data() {
    return {
      columns: [
        {
          text: "Recovery Association",
          value: "RecoveryAssociation",
        },
        {
          text: "Recovery Attempt",
          value: "RecoveryAttempt",
        },
        {
          text: "Reason Code",
          value: "ReasonCode",
        },
        {
          text: "Date",
          value: "Date",
        },
        {
          text: "Dispute Amount",
          value: "DisputeAmount",
        },
        {
          text: "Entered by",
          value: "EnteredBy",
        },
        {
          text: "Response Received",
          value: "ResponseReceived",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/recoveryDetails/retrieve");
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
      recoveryDetails: "getRecoveryDetails",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
  created() {
    this.$store.dispatch("retrieveRecoveryDetails", {
      ClaimId: this.claimId,
      DisputeId: this.disputeId,
    });
  },
};
</script>
    
<style lang="scss" scoped>
</style>