<template>
  <div>
    <checkDepositDetails
      v-if="questionId === 'CheckDepositDetails'"
      @setSelected="(answer) => setAnswer(answer)"
    />
    <everDoneBusiness
      v-else-if="questionId === 'EverDoneBusiness'"
      @setSelected="(answer) => setAnswer(answer)"
    />
    <otherAccount
      v-else-if="questionId === 'OtherAccount'"
      @setSelectedAccount="[setAnswer('AccountSelected')]"
    />
    <selectedValidTransaction
      v-else-if="questionId === 'SelectedValidTransaction'"
      @selectedTransaction="[setAnswer('Selected')]"
    />
    <selectedValidTransaction
      v-else-if="questionId === 'AdditionalRecurringTransactions'"
      @selectedTransaction="[setAnswer('HasRecurring')]"
    />

    <trxOtherAccount
      v-else-if="questionId === 'TrxOtherAccount'"
      @selectedTransaction="[setAnswer('TransactionSelected')]"
    />
    <div v-else>
      There is a section question of an unsupported id: <b>{{ questionId }}</b>
    </div>
  </div>
</template>

<script>
import checkDepositDetails from "./checkDepositDetails";
import everDoneBusiness from "./everDoneBusiness";
import otherAccount from "./otherAccount";
import selectedValidTransaction from "./selectedValidTransaction";
import trxOtherAccount from "./trxOtherAccount";

export default {
  components: {
    checkDepositDetails,
    everDoneBusiness,
    otherAccount,
    selectedValidTransaction,
    trxOtherAccount,
  },
  props: {
    /**
     * Available answers to select
     */
    answerList: {
      type: Array,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  methods: {
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      this.$emit("setAnswer", answer);
    },
  },
};
</script>

<style lang="scss" scoped>
#app .radio-buttons {
  &::v-deep {
    .v-label {
      color: $qvo-color-util-neutral-dark;
      font-size: 1em;
      top: 0;
      margin-bottom: 0;
    }
  }
}
</style>