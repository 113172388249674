<template>
  <div class="task-type">
    <div class="header" :class="isExpanded && 'is-expanded'" @click="toggleExpanded">
      <qvoButton text class="expand-arrow">
        <v-icon>mdi-chevron-right</v-icon>
      </qvoButton>
      <p class="subtitle-1 label">{{ this.label }}</p>
      <p class="count">{{ this.count }}</p>
    </div>
    <typeTable
      v-if="isExpanded"
      :itemCount="count"
      :label="label"
      @openTask="$emit('openTask', $event)"
    />
  </div>
</template>
      
<script>
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import typeTable from "@/components/task-type-table";

export default {
  components: {
    qvoButton,
    typeTable,
  },
  props: {
    count: {
      type: Number,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
      
<style lang="scss" scoped>
.task-type {
  .header {
    height: 49px;
    padding: 0 $qvo-spacing-24 0 $qvo-spacing-16;
    background: $qvo-color-blue-400;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: $qvo-spacing-24;
    cursor: pointer;
    .expand-arrow {
      min-width: unset;
      width: 24px;
      height: 24px;
      padding: 0;
      &:hover {
        background-color: unset;
      }
      &::v-deep {
        .v-icon {
          color: $qvo-color-grey-000;
          transition: transform 0.3s;
        }
      }
    }
    &.is-expanded {
      border-radius: 8px 8px 0 0;
      .expand-arrow {
        &::v-deep {
          .v-icon {
            transform: rotate(90deg);
          }
        }
      }
    }
    .label {
      color: $qvo-color-grey-000;
      flex-grow: 1;
    }
    .count {
      font-size: 24px;
      font-weight: 800;
      color: $qvo-color-grey-000;
    }
  }
  &::v-deep {
    .label {
      margin-bottom: 0;
    }
    .v-data-table {
      border-radius: 0 0 8px 8px;
    }
  }
}
</style>