<template>
  <div>
    <checkbox
      v-for="answer in answerList"
      v-model="selectedCheckboxes"
      :key="answer.Identifier"
      :checkboxValue="answer.Identifier"
      :label="answer.DisplayText"
      :disabled="disabled"
    />
    <p v-if="validationMessage && showErrorMessage" class="body-2 warning">
      {{ validationMessage }}
    </p>
  </div>
</template>

<script>
import checkbox from "@/qux-common/qux22/components/molecules/checkbox";

export default {
  components: {
    checkbox,
  },
  data() {
    return {
      form: null, // To store the reference to the nearest v-form
      selectedCheckboxes: [],
      showErrorMessage: false,
      validationMessage: null,
    };
  },
  props: {
    /**
     * Available answers to select
     */
    answerList: {
      type: Array,
    },
    type: {
      type: String,
    },
    value: {
      type: String,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: (v) => (v && v.length > 0) || "Selection is required",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return !!this.validationMessage;
    },
    valid() {
      return !this.hasError;
    },
  },
  methods: {
    findForm() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options && parent.$options.name === "v-form") {
          this.form = parent;
          break;
        }
        parent = parent.$parent;
      }
    },
    reset() {
      this.showErrorMessage = false;
      this.validationMessage = null;
    },
    resetValidation() {
      this.reset();
    },
    triggerValidation() {
      this.showErrorMessage = true; // Show error message on user interaction
      this.validate();
    },
    validate() {
      const result = this.rules(this.selectedCheckboxes);
      this.validationMessage = result === true ? null : result;
      return !!this.valid;
    },
  },
  watch: {
    selectedCheckboxes() {
      this.triggerValidation();
      this.$emit("setAnswer", this.selectedCheckboxes);
    },
  },
  mounted() {
    this.findForm(); // Find the nearest v-form
    if (this.form) {
      this.form.register(this);
    }
    this.validate();
  },
  beforeDestroy() {
    if (this.form) {
      this.form.unregister(this);
    }
  },
};
</script>

<style lang="scss" scoped>
.warning {
  color: $qvo-color-warning;
}
</style>