<template>
  <disputeDetailsWrapper :claimId="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId">
    <div class="tasks" :class="showBottomBar && 'task-open'">
      <task
        v-if="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId && selectedClaim.viewTask"
        :claimId="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId"
        :taskLabel="selectedClaim.viewTask"
        @closeTask="closeCorrespondence"
      />
      <div v-else>
        <h5>Tasks</h5>
        <tasksDataTable @viewTask="viewTask" />
      </div>
      <bottomBar
        v-if="showBottomBar"
        :style="`width: calc(100% - ${panelWidth}px); left: ${panelWidth}px;`"
      >
        <qvoButton text @click="closeTask">Cancel</qvoButton>
        <qvoButton @click="handleApplyAction">Apply</qvoButton>
      </bottomBar>
      <denyCommentaryModal
        v-model="denyCommentaryModal.show"
        :action="denyCommentaryModal.action"
        :ClaimId="selectedClaim && selectedClaim.data && selectedClaim.data.ClaimId"
        :detailValue="denyCommentaryModal.detailValue"
        @closeModal="denyCommentaryModal.show = false"
        @save="handleApplyAction"
      />
    </div>
  </disputeDetailsWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import bottomBar from "@/components/bottom-bar";
import denyCommentaryModal from "@/components/deny-commentary-modal";
import disputeDetailsWrapper from "@/views/dispute-details";
import task from "@/components/task";
import tasksDataTable from "@/components/claim-tasks-data-table";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    bottomBar,
    denyCommentaryModal,
    disputeDetailsWrapper,
    task,
    tasksDataTable,
    qvoButton,
  },
  data() {
    return {
      denyCommentaryModal: {
        show: false,
        detailValue: "",
        action: "",
      },
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/task/list");
    },
    showBottomBar() {
      return this.selectedClaim.viewTask && !this.disputeDetailsIsShowing;
    },
    ...mapGetters({
      activeClientId: "getActiveClientId",
      selectedClaim: "getSelectedClaim",
      panelWidth: "getTotalPanelWidth",
      pendingCalls: "getPendingCallList",
      taskActions: "getTaskActions",
      taskDetails: "getTaskDetails",
      taskDetailsList: "getTaskDetailsList",
    }),
  },
  methods: {
    closeTask() {
      this.$store.dispatch("setOpenTask", {
        ClaimId: this.selectedClaim.data.ClaimId,
        TaskLabel: undefined,
      });
    },
    retrieveTaskSummary() {
      this.$store.dispatch("retrieveTaskSummary", {
        ClientId: this.activeClientId,
      });
    },
    viewTask(item) {
      this.$store.dispatch("setOpenTask", {
        ClaimId: this.selectedClaim.data.ClaimId,
        TaskLabel: item.TaskLabel,
      });
    },
    async handleApplyAction() {
      const ClaimId = this.selectedClaim && this.selectedClaim.data && this.selectedClaim.data.ClaimId;
      const TaskLabel = this.selectedClaim && this.selectedClaim.viewTask;
      const tasksWithMultipleActionsToSelect = ["Execute Accounting"];

      const hasMultipleActionsToSelect =
        tasksWithMultipleActionsToSelect.includes(TaskLabel);

      const activeTasks =
        (this.taskDetailsList[ClaimId] &&
          this.taskDetailsList[ClaimId][TaskLabel] &&
          this.taskDetailsList[ClaimId][TaskLabel].TaskDetailList) ||
        [];

      // check that all tasks have actions set
      const activeTaskIds = activeTasks.map((task) => task.TaskId);
      const doAllTasksHaveAValueSet = activeTaskIds.every((id) =>
        Object.prototype.hasOwnProperty.call(this.taskActions, id)
      );

      if (!doAllTasksHaveAValueSet) {
        this.$store.dispatch("notifications/addNotification", {
          status: "error",
          message: hasMultipleActionsToSelect
            ? "Please select an action for all items before continuing."
            : "Please select an action to be applied before continuing.",
        });
        return;
      }

      // format and submit data
      const groupTasksByAction = (activeTaskIds, tasks) => {
        const result = {};

        for (const id of activeTaskIds) {
          const action = tasks[id];
          // continue only if the action exists for the id
          if (action) {
            if (!result[action]) {
              result[action] = { TaskList: [] };
            }
            result[action].TaskList.push({ TaskId: id });
          }
        }

        return result;
      };

      const groupedTasks = groupTasksByAction(activeTaskIds, this.taskActions);

      // Check for deny commentary modal display
      const declineAction = "Decline Approval";
      const needsDeclineCommentary =
        Object.keys(groupedTasks).includes(declineAction) &&
        !(
          this.taskDetails[ClaimId] &&
          this.taskDetails[ClaimId][declineAction] &&
          this.taskDetails[ClaimId][declineAction]["ApprovalDeniedCommentary"]
        );
      if (needsDeclineCommentary) {
        this.denyCommentaryModal.show = true;
        this.denyCommentaryModal.detailValue = "ApprovalDeniedCommentary";
        this.denyCommentaryModal.action = declineAction;
        return;
      }

      let hasErrors = false;
      for (const [action, task] of Object.entries(groupedTasks)) {
        const details = this.taskDetails[ClaimId] && this.taskDetails[ClaimId][action] || undefined;
        await this.$store
          .dispatch("saveTaskAction", {
            Action: action,
            Detail: details,
            TaskList: task.TaskList,
          })
          .catch(() => {
            hasErrors = true;
          });
      }

      if (!hasErrors) {
        this.$store.dispatch("notifications/addNotification", {
          status: "success",
          message: `${TaskLabel} has been submitted.`,
        });
        this.closeTask();
        setTimeout(() => {
        this.retrieveTaskSummary();
      }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks {
  height: 100%;
  padding-bottom: 22px;
  &.task-open {
    height: calc(100% - 74px);
  }
}

h5 {
  margin-bottom: $qvo-spacing-24;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 10;
  transition: width 200ms;
}
</style>