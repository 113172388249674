<template>
  <div>
    <backButton @click="closeMessage" />

    <div class="heading">
      <h5>
        {{ correspondenceData.ThreadSubject || correspondenceData.Document }}
      </h5>
      <v-menu offset-y zIndex="101">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="this.hasAttachment" @click="downloadMessage(messageId)">
            <v-list-item-title> Download </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <tag v-if="correspondenceData.TransmissionStatus">{{
        correspondenceData.TransmissionStatus
      }}</tag>
    </div>
    <subHeaderFields :fields="subHeaderFields" />
    <div class="message-body" v-html="this.correspondence.Body || this.correspondenceData.Message" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import backButton from "@/components/back-button";
import subHeaderFields from "@/components/sub-header-fields";
import tag from "@/qux-common/qux22/components/molecules/tag";

export default {
  components: {
    backButton,
    subHeaderFields,
    tag,
  },
  props: {
    messageId: {
      type: String,
    },
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/correspondence/retrieve");
    },
    hasAttachment() {
      return this.correspondence.AttachmentAvailable !== false;

    },
    correspondenceData() {
      return this.selectedClaim.correspondence.find(
        (correspondence) => correspondence.LinkReference === this.messageId
      );
    },
    messageDownload() {
      return `data:application/octet-stream;charset=utf-8;base64,${this.attachment.DocumentStream}`;
    },
    subHeaderFields() {
      return [
        { label: "To", data: this.correspondenceData.To },
        { label: "From", data: this.correspondenceData.From || this.correspondenceData.CreateName },
        { label: "Channel", data: this.correspondenceData.Channel },
      ]
    },
    ...mapGetters({
      attachment: "getAttachment",
      correspondence: "getCorrespondence",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    closeMessage() {
      this.$emit("closeMessage");
    },
    downloadMessage(corrKey) {
      this.$store.dispatch("retrieveAttachment", corrKey).then(() => {
        var downloadLink = document.createElement("a");
        downloadLink.href = this.messageDownload;
        downloadLink.download = this.attachment.AttachmentName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    },
  },
  created() {
    /**
     * Fetch the message
     */
    this.$store.dispatch("retrieveCorrespondence", this.messageId);
    /**
     * Fetch the correspondenceList if it's not already stored
     */
    if (!this.selectedClaim.correspondence.length)
      this.$store.dispatch(
        "retrieveCorrespondenceList",
        this.selectedClaim.data.ClaimId
      );
  },
};
</script>

<style lang="scss" scoped>
.back-button.v-btn.v-size--default {
  margin-bottom: $qvo-spacing-16;
}

.heading {
  display: flex;
  gap: $qvo-spacing-8;
  align-items: center;
  margin-bottom: 12px;
}

@import "@/design/confirmation-message";
</style>