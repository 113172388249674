<template>
  <div class="action-selection">
    <slot />
  </div>
</template>
            
<script>
export default {};
</script>
            
<style lang="scss" scoped>
.action-selection {
    width: 100%;
    background: $qvo-color-blue-100;
    border-radius: 8px;
    padding: $qvo-spacing-24;
    display: flex;
    gap: 56px;
}
</style>