<template>
  <div class="account-number">
    <p v-if="hidden">{{maskedAccount(accountNumber)}}</p>
    <p v-else>{{ accountNumber }}</p>
    <v-icon @click="toggleHidden">mdi-eye-outline</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    accountNumber: {
      type: String,
    },
  },
  data() {
    return {
      hidden: true,
    };
  },
  methods: {
    maskedAccount(accountNumber) {
      return `x${accountNumber.slice(-4)}`;
    },
    toggleHidden() {
      this.hidden = !this.hidden;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-number {
    display: flex;
    align-items: center;
    gap: 4px;
}
p {
    margin-bottom: 0;
}
</style>