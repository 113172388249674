<template>
  <div>
    <backButton @click="closeAttachment" />

    <div class="heading">
      <h5>{{ attachment.AttachmentName }}</h5>
    </div>
    <div
      class="attachment"
      :class="fileTypeInfo && fileTypeInfo.typename === 'pdf' && 'is-pdf'"
    >
      <v-skeleton-loader v-if="isLoading" type="image" />
      <object
        v-if="attachment.DocumentStream && fileTypeInfo && !isLoading"
        :key="attachment.AttachmentName"
        :type="fileTypeInfo.mime"
        :data="attachmentData"
      >
        <p />
        <!-- Empty tag as fallback for when browser can't display attachment data -->
      </object>
    </div>
    <div class="download">
      <v-skeleton-loader v-if="isLoading" type="button" />
      <qvoButton
        outlined
        v-if="attachment.DocumentStream && !isLoading"
        :href="attachmentData"
        :download="attachment.AttachmentName"
        ><v-icon>mdi-download</v-icon>Download</qvoButton
      >
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { toUint8Array } from "js-base64";
import { filetypeinfo } from "magic-bytes.js";

import backButton from "@/components/back-button";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    backButton,
    qvoButton,
  },
  props: {
    attachmentId: {
      type: String,
    },
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/attachments/retrieve");
    },
    fileTypeInfo() {
      const fileBuffer = toUint8Array(this.attachment.DocumentStream);
      const info = filetypeinfo(fileBuffer);

      const infoByExtension = info.find(
        (i) => i.extension === this.fileExtension
      );
      if (infoByExtension) return infoByExtension;

      return info[0];
    },
    fileExtension() {
      if (!this.attachment.AttachmentName) return;
      return this.attachment.AttachmentName.split(".").pop();
    },
    attachmentData() {
      return `data:${
        this.fileTypeInfo ? this.fileTypeInfo.mime : "text/plain"
      };base64,${this.attachment.DocumentStream}`;
    },
    ...mapGetters({
      attachment: "getAttachment",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    closeAttachment() {
      this.$emit("closeAttachment");
    },
  },
  created() {
    this.$store.dispatch("retrieveAttachment", this.attachmentId);
  },
};
</script>
  
<style lang="scss" scoped>
.back-button.v-btn.v-size--default {
  margin-bottom: $qvo-spacing-16;
}

.heading {
  display: flex;
  gap: $qvo-spacing-8;
  align-items: center;
  margin-bottom: 12px;
}

.attachment {
  display: block;
  margin: $qvo-spacing-16 0;
  position: relative;
  width: fit-content;
  height: fit-content;
  &.is-pdf {
    min-height: 50vh;
    height: 100%;
    width: 100%;
    object {
      min-height: 50vh;
      height: 100%;
      width: 100%;
    }
  }
}
</style>