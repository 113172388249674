<template>
  <main id="content" class="case-view">
    <pageTabHeader>
      <div class="top-row">
        <div class="header-left">
          <h5>Transaction Dispute</h5>
          <caseHeader :claim="selectedClaim" :isLoading="isLoading" />
        </div>
      </div>
      <tabs grow :items="tabItems" @change="setTab" :value="tab" />
    </pageTabHeader>
    <tabContent>
      <overview v-if="tab === 'overview'" />
      <investigationDetails v-if="tab === 'investigationDetails'" />
      <communication v-if="tab === 'communication'" />
      <attachments v-if="tab === 'attachments'" />
      <tasks v-if="tab === 'tasks'" />

      <!--
      <addNote v-if="!isLoading && this.$route.params.action === 'addNote'" />
      <requestReopened
        v-if="!isLoading && this.$route.params.action === 'requestReopened'"
      />
      <requestWithdrawn
        v-if="!isLoading && this.$route.params.action === 'requestWithdrawn'"
      />
      <uploadDocumentation
        v-if="!isLoading && this.$route.params.action === 'uploadDocumentation'"
      />
      <uploadFile
        v-if="!isLoading && this.$route.params.action === 'uploadFile'"
      />
      <viewClaimDetails
        v-if="!isLoading && this.$route.params.action === 'viewClaimDetails'"
      />
      -->
    </tabContent>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import caseHeader from "@/components/case-header";
import pageTabHeader from "@/components/page-tab-header";
import tabContent from "@/components/tab-content";
import tabs from "@/qux-common/qux22/components/molecules/tabs";

import overview from "./overview";
import communication from "./communication";
import investigationDetails from "./investigation-details";
import attachments from "./attachments";
import tasks from "./tasks";

/*
import addNote from "./add-note";
import requestReopened from "./request-reopened";
import requestWithdrawn from "./request-withdrawn";
import uploadDocumentation from "./upload-documentation";
import uploadFile from "./upload-file";
import viewClaimDetails from "./view-case-details";
*/

export default {
  components: {
    caseHeader,
    pageTabHeader,
    tabContent,
    tabs,
    overview,
    communication,
    investigationDetails,
    attachments,
    tasks,
    /*
    addNote,
    requestReopened,
    requestWithdrawn,
    uploadDocumentation,
    uploadFile,
    viewClaimDetails,
    */
  },
  methods: {
    setTab(tab) {
      this.$store.dispatch("setClaimTab", {
        ClaimId: this.selectedClaim.data.ClaimId,
        tab,
      });
    },
  },
  computed: {
    isLoading() {
      return (
        this.selectedClaim &&
        this.selectedClaim.data &&
        this.selectedClaim.data.ClaimId !== this.$route.params.id
      );
    },
    actions() {
      return this.selectedClaim && this.selectedClaim.data && this.selectedClaim.data.Actions
        ? this.selectedClaim.data.Actions.map((action) => action.ActionName)
        : [];
    },
    tab() {
      return (this.selectedClaim && this.selectedClaim.tab) || "overview";
    },
    tabItems() {
      const tabs = [{ name: "Overview", href: "#overview" }];
      if (this.actions.includes("View Investigation Details"))
        tabs.push({
          name: "Investigation Details",
          href: "#investigationDetails",
        });
      if (this.actions.includes("View Communication"))
        tabs.push({ name: "Communication", href: "#communication" });
      if (this.actions.includes("View Case Attachments"))
        tabs.push({ name: "Attachments", href: "#attachments" });

      //always show for now. /claim/retrieve will return a flag later
      tabs.push({
        name: "Tasks",
        href: "#tasks",
        leftIcon:
          this.selectedClaim &&
          this.selectedClaim.data &&
          this.selectedClaim.data.HasOutstandingTasks &&
          "mdi-alert-outline",
        leftIconTooltip:
          this.selectedClaim &&
          this.selectedClaim.data &&
          this.selectedClaim.data.HasOutstandingTasks &&
          "There are outstanding tasks available to work",
      });

      return tabs;
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
    }),
  },
  created() {
    if (
      !(
        this.$store.getters.getSelectedClaim &&
        this.$store.getters.getSelectedClaim.data &&
        this.$store.getters.getSelectedClaim.data.ClaimId
      )
    ) {
      this.$store.dispatch("setSelectedClaim", this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.case-view {
  height: calc(100% - #{$page-tab-height} - 9px);
  &:has(.bottom-bar) {
    height: calc(100% - #{$page-tab-height} - 9px - 74px);
  }
}

.page-tab-header {
  padding: 16px 0;
  flex-direction: column;
  padding-bottom: 0;
  gap: unset;
  &::v-deep {
    .v-tab {
      text-transform: none;
    }
  }
  .top-row {
    padding: 0 40px;
  }
  .header-left {
    display: flex;
    align-content: center;
    gap: $qvo-spacing-16;
  }
}

.content {
  padding: $qvo-spacing-24;
}
</style>