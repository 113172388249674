<template>
  <div class="transactions-box">
    <dataTable
      :headers="headers"
      :items="transactionDetails"
      item-key="TransactionId"
    >
      <template #item.Date="{ item }">
        {{ formatDate(item.Date) }}
      </template>
      <template #item.Amount="{ item }">
        {{ formatAmount(item.Amount) }}
      </template>
    </dataTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Merchant Name",
          value: "Description",
        },
        {
          text: "Date",
          value: "Date",
        },
        {
          text: "Card #",
          value: "CardNumberEndingIn",
        },
        {
          text: "Amount",
          value: "Amount",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      transactionDetails: "getCombinedIntakeTransactions",
    }),
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions-box {
}
</style>