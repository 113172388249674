<template>
  <div>
    <div class="selected-transactions-overview" v-if="hasTransactionDetails">
      <div class="total">
        <h6>Total Dispute Amount:</h6>
        <h5>{{ formatAmount(totalTransactionAmount) }}</h5>
      </div>
      <qvoButton
        class="view-transactions"
        outlined
        @click="openTransactionsModal"
      >
        View ({{ transactionCount }}) Transaction{{
          transactionCount === 1 ? "" : "s"
        }}
      </qvoButton>
    </div>
    <disputedTransactionsModal
      :isOpen="showTransactionsModal"
      @closeModal="closeTransactionsModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatAmount } from "@/util/format";
import disputedTransactionsModal from "@/components/disputed-transactions-modal";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    disputedTransactionsModal,
    qvoButton,
  },
  data() {
    return {
      showTransactionsModal: false,
    };
  },
  computed: {
    hasTransactionDetails() {
      return !!this.transactionCount;
    },
    totalTransactionAmount() {
      if (!this.transactionDetails) return 0;
      return this.transactionDetails.reduce((accumulator, transaction) => {
        return Number(accumulator) + Number(transaction.Amount);
      }, 0);
    },
    transactionCount() {
      return (this.transactionDetails && this.transactionDetails.length) || 0;
    },
    ...mapGetters({
      transactionDetails: "getCombinedIntakeTransactions",
    }),
  },
  methods: {
    openTransactionsModal() {
      this.showTransactionsModal = true;
    },
    closeTransactionsModal() {
      this.showTransactionsModal = false;
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-transactions-overview {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  padding: $qvo-spacing-24;
  border-radius: $qvo-spacing-8;
  box-shadow: $qvo-shadow-1;
  height: fit-content;
  width: 400px;
}
.total {
  display: flex;
  justify-content: space-between;
  gap: $qvo-spacing-24;
}
.view-transactions {
  width: fit-content;
}
</style>