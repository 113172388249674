<template>
  <section>
    <backButton v-if="!backButtonHidden" @click="goBack" />
    <p class="subtitle-1">
      Are you sure you want to cancel? This case will be closed.
    </p>
    <textInput
      multiline
      placeholder="Reason for Withdrawing"
      @change="setCancelReason"
    />
  </section>
</template>
  
  <script>
import { mapGetters } from "vuex";
import backButton from "@/components/back-button";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    backButton,
    textInput,
  },
  data() {
    return {
      reason: "",
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/withdrawRequest");
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    goBack() {
      this.$store.dispatch("intakeNavigationBack");
    },
    setCancelReason(reason) {
      this.$store.dispatch("setIntakeCancelReason", {
        reason,
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
</style>