<template>
    <section class="card">
      <div class="title">
        <h6>{{ title }}</h6>
      </div>
      <div class="content">
        <slot />
      </div>
    </section>
  </template>
  
  <script>  
  export default {
    name: "Card",
    props: {
      title: {
        type: String,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .card {
    scroll-margin-top: $qvo-spacing-24;
  }
  .title {
    padding: 12px 24px;
    background: $qvo-color-blue-400;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    gap: $qvo-spacing-8;
    h6 {
      color: $qvo-color-grey-000;
    }
  }
  .content {
    border: 1px solid $qvo-color-blue-400;
    border-radius: 0 0 8px 8px;
    padding: $qvo-spacing-24 $qvo-spacing-24 $qvo-spacing-8 $qvo-spacing-24;
  }
  </style>