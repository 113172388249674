<template>
  <div>
    <disputeHeader
      :claimId="claimId"
      :disputeId="disputeId"
      title="Applied Merchant Credits"
      @close="close"
    />

    <dataTable
      :headers="columns"
      :items="isLoading ? [] : appliedCredits"
      itemKey="Amount"
      :itemsPerPage="8"
      :loading="isLoading"
    >
      <template #item.Date="{ item }">
        {{ formatDate(item.Date) }}
      </template>
      <template #item.Amount="{ item }">
        {{ formatAmount(item.Amount) }}
      </template>
      <template #item.AppliedAmount="{ item }">
        {{ formatAmount(item.AppliedAmount) }}
      </template>
    </dataTable>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { formatAmount, formatDate } from "@/util/format";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import disputeHeader from "./dispute-header";

export default {
  components: {
    dataTable,
    disputeHeader,
  },
  props: {
    claimId: {
      type: String,
    },
    disputeId: {
      type: String,
    },
  },
  data() {
    return {
      columns: [
        {
          text: "Date",
          value: "Date",
        },
        {
          text: "Description",
          value: "Description",
        },
        {
          text: "Dispute Amount",
          value: "Amount",
        },
        {
          text: "Amount Applied",
          value: "AppliedAmount",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/appliedCredits/retrieve");
    },
    ...mapGetters({
      appliedCredits: "getAppliedCredits",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatAmount(amount) {
      return formatAmount(amount);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
  created() {
    this.$store.dispatch("retrieveAppliedCredits", {
      ClaimId: this.claimId,
      DisputeId: this.disputeId,
    });
  },
};
</script>
  
<style lang="scss" scoped>
</style>