<template>
  <section class="accountholder-search">
    <h6>Accountholder Search</h6>
    <div class="search-container">
      <dropdown
        class="search-type"
        v-model="searchType"
        :items="searchTypes"
        @change="debouncedSearch"
      />
      <textInput
        class="search-input"
        v-if="searchType === 'AccountNumber'"
        v-model="search.AccountNumber"
        placeholder="Account Number"
        @input="debouncedSearch"
        prepend-inner-icon="mdi-magnify"
      />
      <textInput
        class="search-input"
        v-if="searchType === 'SSNTIN'"
        v-model="search.SSNTIN"
        placeholder="SSN/TIN"
        @input="debouncedSearch"
        prepend-inner-icon="mdi-magnify"
      />
      <textInput
        class="search-input"
        v-if="searchType === 'CardNumber'"
        v-model="search.CardNumber"
        placeholder="Card Number"
        @input="debouncedSearch"
        prepend-inner-icon="mdi-magnify"
      />
      <textInput
        class="search-input"
        v-if="searchType === 'CustomerId'"
        v-model="search.CustomerId"
        placeholder="Customer ID"
        @input="debouncedSearch"
        prepend-inner-icon="mdi-magnify"
      />
    </div>
    <div class="name-address" v-if="searchType === 'NameAddress'">
      <div class="name-fields">
        <textInput
          class="first-name"
          v-model="search.Name.First"
          label="First Name"
          @input="debouncedSearch"
        />
        <textInput
          class="last-name"
          v-model="search.Name.Last"
          label="Last Name"
          @input="debouncedSearch"
        />
      </div>
      <div class="address-fields">
        <textInput
          class="address"
          v-model="search.Address.Line1"
          label="Address"
          @input="debouncedSearch"
        />
        <textInput
          class="city"
          v-model="search.Address.City"
          label="City"
          @input="debouncedSearch"
        />
        <dropdown
          class="state"
          v-model="search.Address.State"
          :items="states"
          label="State"
          @change="debouncedSearch"
        />
        <textInput
          class="zip"
          v-model="search.Address.Zip"
          label="Zipcode"
          @input="debouncedSearch"
        />
      </div>
    </div>
    <dataTable
      :value="selectedCustomer"
      :headers="searchResultHeaders"
      :items="customers || []"
      item-key="CustomerId"
      :loading="isLoading"
      showSelect
      singleSelect
      @input="setSelectedCustomer"
    >
    </dataTable>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    dataTable,
    dropdown,
    textInput,
  },
  data() {
    return {
      searchType: "AccountNumber",
      search: {
        AccountNumber: "",
        SSNTIN: "",
        CardNumber: "",
        ClientId: "",
        CustomerId: "",
        Name: {
          First: "",
          Last: "",
        },
        Address: {
          Line1: "",
          City: "",
          Zip: "",
        },
      },
      searchTypes: [
        { text: "Account Number", value: "AccountNumber" },
        { text: "SSN/TIN", value: "SSNTIN" },
        { text: "Card Number", value: "CardNumber" },
        { text: "Customer ID", value: "CustomerId" },
        { text: "Name / Address", value: "NameAddress" },
      ],
      searchResultHeaders: [
        {
          text: "Name",
          value: "Name.FullName",
        },
        {
          text: "Address",
          value: "Address.LongAddress",
        },
        {
          text: "Email",
          value: "EmailAddress",
        },
        {
          text: "Phone",
          value: "Phone.Number",
        },
        {
          text: "Accountholder ID",
          value: "CustomerId",
        },
      ],
      states: [
        {
          text: "Alabama",
          value: "AL",
        },
        {
          text: "Alaska",
          value: "AK",
        },
        {
          text: "Arizona",
          value: "AZ",
        },
        {
          text: "Arkansas",
          value: "AR",
        },
        {
          text: "California",
          value: "CA",
        },
        {
          text: "Colorado",
          value: "CO",
        },
        {
          text: "Connecticut",
          value: "CT",
        },
        {
          text: "Delaware",
          value: "DE",
        },
        {
          text: "District of Columbia",
          value: "DC",
        },
        {
          text: "Florida",
          value: "FL",
        },
        {
          text: "Georgia",
          value: "GA",
        },
        {
          text: "Hawaii",
          value: "HI",
        },
        {
          text: "Idaho",
          value: "ID",
        },
        {
          text: "Illinois",
          value: "IL",
        },
        {
          text: "Indiana",
          value: "IN",
        },
        {
          text: "Iowa",
          value: "IA",
        },
        {
          text: "Kansas",
          value: "KS",
        },
        {
          text: "Kentucky",
          value: "KY",
        },
        {
          text: "Louisiana",
          value: "LA",
        },
        {
          text: "Maine",
          value: "ME",
        },
        {
          text: "Maryland",
          value: "MD",
        },
        {
          text: "Massachusetts",
          value: "MA",
        },
        {
          text: "Michigan",
          value: "MI",
        },
        {
          text: "Minnesota",
          value: "MN",
        },
        {
          text: "Mississippi",
          value: "MS",
        },
        {
          text: "Missouri",
          value: "MO",
        },
        {
          text: "Montana",
          value: "MT",
        },
        {
          text: "Nebraska",
          value: "NE",
        },
        {
          text: "Nevada",
          value: "NV",
        },
        {
          text: "New Hampshire",
          value: "NH",
        },
        {
          text: "New Jersey",
          value: "NJ",
        },
        {
          text: "New Mexico",
          value: "NM",
        },
        {
          text: "New York",
          value: "NY",
        },
        {
          text: "North Carolina",
          value: "NC",
        },
        {
          text: "North Dakota",
          value: "ND",
        },
        {
          text: "Ohio",
          value: "OH",
        },
        {
          text: "Oklahoma",
          value: "OK",
        },
        {
          text: "Oregon",
          value: "OR",
        },
        {
          text: "Pennsylvania",
          value: "PA",
        },
        {
          text: "Puerto Rico",
          value: "PR",
        },
        {
          text: "Rhode Island",
          value: "RI",
        },
        {
          text: "South Carolina",
          value: "SC",
        },
        {
          text: "South Dakota",
          value: "SD",
        },
        {
          text: "Tennessee",
          value: "TN",
        },
        {
          text: "Texas",
          value: "TX",
        },
        {
          text: "Utah",
          value: "UT",
        },
        {
          text: "Vermont",
          value: "VT",
        },
        {
          text: "Virginia",
          value: "VA",
        },
        {
          text: "Virgin Islands",
          value: "VI",
        },
        {
          text: "Washington",
          value: "WA",
        },
        {
          text: "West Virginia",
          value: "WV",
        },
        {
          text: "Wisconsin",
          value: "WI",
        },
        {
          text: "Wyoming",
          value: "WY",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/retrieveCustomers");
    },
    ...mapGetters({
      pendingCalls: "getPendingCallList",
      activeClientId: "getActiveClientId",
      customers: "getCustomers",
      selectedCustomer: "getSelectedCustomer",
    }),
  },
  methods: {
    setSelectedCustomer(customer) {
      this.$store.dispatch("setSelectedCustomer", {
        customer,
      });
    },
  },
  created() {
    this.debouncedSearch = this._.debounce(() => {
      if (this.searchType === "NameAddress") {
        this.$store.dispatch("retrieveCustomers", {
          ClientId: this.activeClientId,
          Name: this.search.Name,
          Address: this.search.Address,
        });
      } else {
        var searchParams = this.search[this.searchType];
        this.$store.dispatch("retrieveCustomers", {
          ClientId: this.activeClientId,
          [this.searchType]: searchParams,
        });
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.accountholder-search {
  height: calc(100% - 74px);
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-16;
}
.search-container {
  display: flex;
  gap: $qvo-spacing-24;
  .search-type {
    width: 162px;
  }
  .search-input {
    width: 446px;
  }
}
</style>