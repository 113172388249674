<template>
  <section class="investigation-details">
    <h5>Investigation Details</h5>
    <textInput
      v-model="search"
      class="search"
      prependInnerIcon="mdi-magnify"
      placeholder="Search by any field"
    />
    <dataTable
      :headers="headers"
      :items="isLoading ? [] : investigationDetailsList"
      itemKey="LinkReference"
      :loading="isLoading"
      :search="search"
    >
    </dataTable>
  </section>
</template>
  
<script>
import { mapGetters } from "vuex";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    dataTable,
    textInput,
  },
  data() {
    return {
      /**
       * Data table headers
       */
      headers: [
        {
          text: "Topic",
          align: "start",
          value: "Label",
        },
        {
          text: "Results",
          align: "start",
          value: "InvestigationResults",
        },
        {
          text: "Entered By",
          align: "start",
          value: "EnteredBy",
        },
        {
          text: "Entered On",
          align: "start",
          value: "displayDate",
        },
      ],
      search: undefined,
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/investigationDetails/retrieve");
    },
    ...mapGetters({
      investigationDetailsList: "getInvestigationDetailsList",
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    retrieveInvestigationDetails() {
      this.$store.dispatch(
        "retrieveInvestigationDetails",
        this.selectedClaim.data.ClaimId
      );
    },
  },
  mounted() {
    if (!this.selectedClaim.correspondence) this.retrieveInvestigationDetails();
  },
  watch: {
    selectedClaim(claim) {
      if (!claim.correspondence) this.retrieveInvestigationDetails();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.investigation-details {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  .search {
    max-width: 446px;
  }
}
</style>