<template>
  <div>
    <section class="communication-data-table">
      <div class="search">
        <textInput
          v-model="search"
          class="search"
          prependInnerIcon="mdi-magnify"
          placeholder="Search"
        />
        <qvoButton @click="openSendMessageModal()" class="send-message"
          >Send Message</qvoButton
        >
      </div>
      <dataTable
        :headers="headers"
        :items="isLoading ? [] : selectedClaim.correspondence"
        itemKey="LinkReference"
        :loading="isLoading"
        ref="dataTable"
        :search="search"
        @click:row="viewCorrespondence"
      >
      </dataTable>
    </section>
    <sendMessageModal
      :isOpen="sendMessageModal.isOpen"
      @closeModal="sendMessageModal.isOpen = false"
    />
  </div>
</template>

<script>
/**
 * @fileoverview Communication data table component
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import { mapGetters } from "vuex";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import sendMessageModal from "@/components/send-message-modal";

export default {
  components: {
    dataTable,
    textInput,
    qvoButton,
    sendMessageModal,
  },
  data() {
    return {
      /**
       * Data table headers
       */
      headers: [
        {
          text: "Date",
          align: "start",
          value: "displayDate",
        },
        {
          text: "Document",
          align: "start",
          value: "Document",
        },
        {
          text: "Message Title",
          align: "start",
          value: "ThreadSubject",
        },
        {
          text: "Status",
          align: "start",
          value: "TransmissionStatus",
        },
        {
          align: "end",
          value: "action",
          sortable: false,
        },
      ],
      search: undefined,
      sendMessageModal: {
        isOpen: false,
      },
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/correspondence/list");
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    /**
     * View correspondence when selected
     * @param {Object} item - correspondence item
     */
    viewCorrespondence(item) {
      this.$emit("viewCorrespondence", item);
    },
    retrieveCorrespondenceList() {
      this.$store.dispatch(
        "retrieveCorrespondenceList",
        this.selectedClaim.data.ClaimId
      );
    },
    openSendMessageModal() {
      this.sendMessageModal.isOpen = true;
    },
  },
  mounted() {
    if (!this.selectedClaim.correspondence) this.retrieveCorrespondenceList();
  },
  watch: {
    selectedClaim(claim) {
      if (!claim.correspondence) this.retrieveCorrespondenceList();
    },
  },
};
</script>

<style lang="scss" scoped>
.communication-data-table {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
  .search {
    display: flex;
    justify-content: space-between;
    .input {
      width: 446px;
    }
    .send-message {
      align-self: center;
    }
  }
  .v-data-table {
    &::v-deep {
      tr {
        cursor: pointer;
      }
    }
  }
}
</style>