<template>
  <div class="task-list">
    <type
      v-for="taskType in sortedTaskSummary"
      :key="taskType.TaskLabel"
      :count="taskType.TaskCount"
      :label="taskType.TaskLabel"
      @openTask="$emit('openTask', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import type from "./type";

export default {
  components: {
    type,
  },
  computed: {
    sortedTaskSummary() {
      if (this.taskSummary && Array.isArray(this.taskSummary)) {
        return this.taskSummary
          .slice()
          .sort((a, b) => a.TaskLabel.localeCompare(b.TaskLabel));
      } else {
        return [];
      }
    },
    ...mapGetters({
      taskSummary: "getTaskSummary",
    }),
  },
};
</script>

<style lang="scss" scoped>
.task-list {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
</style>