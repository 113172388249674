<template>
  <div>
    <confirmation v-if="currentStep.name === 'confirmation'" />
    <summaryQuestionnaire v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import confirmation from "./summary/confirmation";
import summaryQuestionnaire from "./summary/summaryQuestionnaire";

export default {
  components: {
    confirmation,
    summaryQuestionnaire,
  },
  computed: {
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
    }),
  },
};
</script>