<template>
  <accountSelectDataTable
    :accountList="accountList"
    @retrieveAccounts="retrieveOtherAccounts"
    @setSelectedAccount="setOtherAccount"
  />
</template>

<script>
import { mapGetters } from "vuex";
import accountSelectDataTable from "@/components/account-select-data-table";

export default {
  components: {
    accountSelectDataTable,
  },
  computed: {
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
      accountList: "getOtherAccounts",
    }),
  },
  methods: {
    retrieveOtherAccounts({ ClaimId }) {
      this.$store.dispatch("retrieveOtherAccounts", {
        ClaimId,
      });
    },
    setOtherAccount(account) {
      if (account) {
        this.$store.dispatch("setSelectedOtherAccount", account.AccountNumber);
        this.$emit("setSelectedAccount");
      } else {
        this.$store.dispatch("setSelectedOtherAccount", "");
      }
      this.$store.dispatch("setSelectedSimilarTransactions", []);
    },
  },
  beforeDestroy() {
    // clear out selection if this answer is no longer valid
    if (this.currentStep.parentStep === 3) {
      this.setOtherAccount();
      this.$store.dispatch("setSelectedSimilarTransactions", []);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>