<template>
    <div class="page-tab-header">
      <slot />
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .page-tab-header {
    position: relative;
    background: $qvo-color-grey-000;
    display: flex;
    justify-content: space-between;
    padding: 16px 40px;
    box-shadow: $qvo-shadow-2;
    z-index: 10;
    gap: $qvo-spacing-24;
  }
  </style>