<template>
  <section class="required-docs">
    <selectedTransactionsOverview class="transactions-overview" />
    <div>
      <p class="subtitle-1">Documentation Required</p>
      <p class="body-2">
        Based on the details you provided, the following items are required to
        prevent any delays in the processing of your claim. We'll send
        instructions and any necessary forms.
      </p>
      <p class="body-2">
        You can also attach any required documents to the case now.
      </p>
      <card class="doc-list-card" title="Required Document List">
        <div
          v-for="doc in docRequirements"
          :key="doc.Identifier"
          class="document"
        >
          <div v-if="!docs[doc.Identifier]" class="file-upload">
            <v-icon size="128">mdi-upload-outline</v-icon>
            <div class="buttons">
              <qvoButton
                outlined
                v-if="doc.TemplateDocumentStream"
                :href="attachmentDownload(doc.TemplateDocumentStream)"
                :download="`${doc.Label}.pdf`"
              >
                Download Template
              </qvoButton>
              <uploadModal
                outlined
                :label="doc.Label"
                :isLoading="attachIsLoading"
                @attachFile="
                  addDocuments({ Identifier: doc.Identifier, ...$event })
                "
              />
            </div>
          </div>
          <div v-else class="file-added">
            <v-icon>mdi-check</v-icon>
            <p class="subtitle-2">{{ doc.Identifier }}:</p>
            <p class="body-2">
              {{ docs[doc.Identifier] && docs[doc.Identifier].AttachmentLabel }}
            </p>
            <qvoButton
              text
              @click="deleteAttachment"
              :disabled="deleteIsLoading"
            >
              <v-progress-circular
                v-if="deleteIsLoading"
                indeterminate
                size="24"
              />
              <v-icon v-else>mdi-delete</v-icon>
            </qvoButton>
          </div>
        </div>
      </card>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import card from "@/components/card";
import selectedTransactionsOverview from "@/components/selected-transactions-overview";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import uploadModal from "./required-docs-upload-modal";

export default {
  components: {
    card,
    selectedTransactionsOverview,
    qvoButton,
    uploadModal,
  },
  data() {
    return {
      file: undefined,
      category: "SupportingDocumentation",
      name: "",
    };
  },
  computed: {
    attachIsLoading() {
      return this.pendingCalls.includes("/claim/intake/addDocuments");
    },
    deleteIsLoading() {
      return (
        this.pendingCalls.includes("/claim/attachments/list") ||
        this.pendingCalls.includes("/claim/attachments/delete")
      );
    },
    docs() {
      return Object.fromEntries(
        this.storedDocs.map((doc) => [doc.Identifier, doc])
      );
    },
    ...mapGetters({
      attachments: "getAttachments",
      storedDocs: "getStoredDocs",
      docRequirements: "getDocRequirements",
      pendingCalls: "getPendingCallList",
      selectedClaim: "getSelectedClaim",
    }),
  },
  methods: {
    attachmentDownload(attachmentData) {
      return `data:application/octet-stream;charset=utf-8;base64,${attachmentData}`;
    },
    retrieveDocRequirements() {
      this.$store.dispatch("retrieveDocRequirements", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
    },
    addDocuments({ Identifier, category, file, name }) {
      this.dialog = false;
      this.$store.dispatch("addDocuments", {
        ClaimId: this.selectedClaim.data.ClaimId,
        file,
        Identifier,
        AttachmentCategory: category,
        AttachmentLabel: name,
      });
    },
    async deleteAttachment() {
      // This is not going to work if we have a situation where multiple attachments are able to be added to claims.
      // In that case, we will need to be able to get the LinkReference back when adding attachments.
      await this.$store.dispatch("listAttachments", {
        ClaimId: this.selectedClaim.data.ClaimId,
      });
      const LinkReference = this.attachments[0].LinkReference;
      await this.$store.dispatch("deleteAttachment", {
        LinkReference,
      });
      this.$store.dispatch("clearIntakeDocs")
    },
  },
  mounted() {
    if (this.docRequirements === undefined) {
      this.retrieveDocRequirements();
    }
  },
};
</script>

<style lang="scss" scoped>
.required-docs {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
  @media only screen and (min-width: 1200px) {
    flex-direction: row-reverse;
  }
  .body-2 {
    color: $qvo-color-grey-600;
    margin-bottom: $qvo-spacing-8;
  }
  .doc-list-card {
    margin-top: 36px;
    .document {
      .file-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px dashed $qvo-color-grey-400;
        border-radius: 8px;
        padding: $qvo-spacing-24;
        .v-icon {
          color: $qvo-color-blue-400;
        }
        .buttons {
          display: flex;
          gap: $qvo-spacing-8;
        }
      }
      .file-added {
        display: flex;
        align-items: center;
        gap: $qvo-spacing-8;
        p {
          margin-bottom: 0;
        }
        .mdi-check {
          color: $qvo-color-success;
        }
        &::v-deep {
          .v-progress-circular__overlay {
            color: $qvo-color-grey-000;
          }
        }
      }
    }
    &::v-deep {
      .content {
        padding: $qvo-spacing-24;
      }
    }
  }
}
</style>