<template>
  <div class="assist-box">
      <slot />
  </div>
</template>

<script>
/**
 * @fileoverview Box showing assistive text
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "assist-box",
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.assist-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>