/* Need to keep the attach prop as the dropdowns won't scroll properly when using containers with `overflow: hidden` * /

<template>
  <div class="wrapper">
    <v-menu
      v-model="menuIsOpen"
      offset-y
      nudgeBottom="6"
      :attach="`.${getAttachClass}`"
    >
      <template v-slot:activator="{ on, attrs }">
        <Button
          outlined
          rightIcon="mdi-chevron-down"
          v-bind="attrs"
          v-on="on"
          :flipRightIcon="menuIsOpen"
        >
          {{ title }}
        </Button>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="[item.action && item.action(), $emit('selected', item)]"
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="menu-attach" :class="getAttachClass" />
  </div>
</template>

<script>
/**
 * @fileoverview Common component for dropdown buttons
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import Button from "@/qux-common/qux22/components/molecules/button";

export default {
  name: "DropdownButton",
  components: { Button },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  computed: {
    getAttachClass() {
      // need a random id for each instance of this component
      return `attach-${Math.floor(Math.random() * 100000)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
</style>

// Generally should avoid non-scoped styles, but this menu is attached to the DOM at a higher level
<style lang="scss">
.v-application .v-menu__content {
  border-radius: 8px;
  box-shadow: $qvo-shadow-2;
  .v-list {
    padding: 0;
    .v-list-item:hover {
      background: $qvo-color-grey-050;
      cursor: pointer;
    }
  }
}
</style>