<template>
  <section class="questionnaire">
    <selectedTransactionsOverview class="transactions-overview" />
    <v-form ref="form" v-if="!isLoading" @input="setQuestionnaireFormValidity">
      <div
        class="questions"
        v-for="question in questionnaire.QuestionList"
        :key="question.Identifier"
      >
        <inputControls
          :question="question"
          :answers="answers"
          displayReasonPrefixText
          @setAnswer="setQuestionnaireAnswer"
          @unsetAnswer="unsetQuestionnaireAnswer"
        />
      </div>
      <div
        class="questions"
        v-for="question in postQuestionnaire.QuestionList"
        :key="question.Identifier"
      >
        <inputControls
          :question="question"
          :answers="answers"
          @setAnswer="setQuestionnaireAnswer"
          @unsetAnswer="unsetQuestionnaireAnswer"
        />
      </div>
    </v-form>
    <v-skeleton-loader v-if="isLoading" type="heading, list-item-three-line" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import inputControls from "../input-controls";
import selectedTransactionsOverview from "@/components/selected-transactions-overview";

export default {
  components: {
    inputControls,
    selectedTransactionsOverview,
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/intake/retrieveQuestionnaire");
    },
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      questionnaire: "getQuestionnaire",
      postQuestionnaire: "getPostQuestionnaire",
      answers: "getQuestionnaireAnswers",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    setQuestionnaireFormValidity(value) {
      this.$store.dispatch("setQuestionnaireFormValidity", {
        value,
      });
    },
    setQuestionnaireAnswer({ questionId, answer }) {
      this.$store.dispatch("setQuestionnaireAnswer", {
        questionId,
        answer,
      });
    },
    unsetQuestionnaireAnswer({ questionId }) {
      if (this.currentStep.name === "questionnaire") {
        // since this is automatically triggered when questions aren't visible in the UI,
        // we don't want this to run when leaving this page, otherwise all answers will unset.
        this.$store.dispatch("unsetQuestionnaireAnswer", {
          questionId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $qvo-spacing-24;
  @media only screen and (min-width: 1200px) {
    flex-direction: row-reverse;
  }
}
</style>