<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    width="680"
    transition="scale-transition"
    origin="middle center"
  >
    <v-card :class="isOverflowing && 'content-scrolls'">
      <div class="header">
        <h6>{{ title }}</h6>
      </div>
      <div class="content" ref="content" :class="hasButtons && 'has-buttons'">
        <slot />
      </div>
      <div v-if="hasButtons" class="buttons">
        <slot name="buttons" />
      </div>
    </v-card>
    <template slot="activator" slot-scope="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <FullscreenLoader v-if="isLoading" />
  </v-dialog>
</template>

<script>
/**
 * @fileoverview Common component for modals
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import FullscreenLoader from "@/qux-common/qux22/components/molecules/fullscreen-loader";

export default {
  name: "Modal",
  components: { FullscreenLoader },
  props: {
    title: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOverflowing: false,
    };
  },
  computed: {
    hasButtons() {
      return !!this.$slots.buttons;
    },
  },
  methods: {
    checkOverflow() {
      const content = this.$refs.content;
      if (content)
        this.isOverflowing = content.scrollHeight > content.clientHeight;
    },
  },
  mounted() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  },
  updated() {
    this.checkOverflow();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkOverflow);
  },
};
</script>

<style lang="scss" scoped>
$modal-header-height: 63px;
$modal-buttons-height: 74px;

.v-dialog__content {
  &::v-deep {
    .v-dialog {
      overflow: hidden;
    }
  }
}
.v-card {
  overflow: hidden;
  height: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}
.content {
  padding: $qvo-spacing-16 $qvo-spacing-24;
  overflow: auto;
  max-height: calc(100% - #{$modal-header-height});
  &.has-buttons {
    max-height: calc(100% - #{$modal-header-height} - #{$modal-buttons-height});
  }
}
.content-scrolls {
  .header {
    border-bottom: 1px solid $qvo-color-grey-200;
  }
  .buttons {
    border-top: 1px solid $qvo-color-grey-200;
  }
}
.header {
  height: $modal-header-height;
  padding: 17px 24px 15px;
  position: sticky;
  top: 0;
}
.buttons {
  height: $modal-buttons-height;
  display: flex;
  justify-content: flex-end;
  gap: $qvo-spacing-8;
  padding: $qvo-spacing-16;
  position: sticky;
  bottom: 0;
}
</style>