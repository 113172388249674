<template>
  <div>
    <v-btn outlined @click="addItem" :disabled="!allowedToAddItem"
      ><v-icon>mdi-plus-circle-outline</v-icon>Add Item</v-btn
    >
    <dataTable
      :headers="headers"
      :items="checkDeposits"
      itemKey="key"
      :showInlineSearch="false"
    >
      <template v-slot:item.CheckNumber="{ item }">
        <qvoInput
          :value="item.CheckNumber"
          @change="
            setValue({ key: item.key, value: $event, field: 'CheckNumber' })
          "
        />
      </template>
      <template v-slot:item.Date="{ item }">
        <date
          :key="item.key"
          :value="item.Date"
          @setAnswer="
            setValue({ key: item.key, item, value: $event, field: 'Date' })
          "
        />
      </template>
      <template v-slot:item.From="{ item }">
        <qvoInput
          :value="item.From"
          @change="setValue({ key: item.key, value: $event, field: 'From' })"
        />
      </template>
      <template v-slot:item.Amount="{ item }">
        <currency
          :value="item.Amount"
          @setAnswer="
            setValue({ key: item.key, value: $event, field: 'Amount' })
          "
        />
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon @click="removeItem(item.key)">mdi-delete</v-icon>
      </template>
    </dataTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "../currency";
import date from "../date";
import qvoInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: { currency, date, qvoInput },
  data() {
    return {
      headers: [
        { text: "Number", value: "CheckNumber" },
        { text: "Date", value: "Date" },
        { text: "From", value: "From" },
        { text: "Amount", value: "Amount" },
        { text: "", value: "delete" },
      ],
    };
  },
  computed: {
    allowedToAddItem() {
      // max of 30 checks allowed
      return this.checkDeposits.length < 30;
    },
    ...mapGetters({
      checkDeposits: "getCheckDeposits",
    }),
  },
  methods: {
    setValue({ key, field, value }) {
      const checkDeposits = this.checkDeposits;
      const index = checkDeposits.findIndex((item) => item.key === key);
      checkDeposits[index][field] = value;
      this.$store.dispatch("setCheckDeposits", checkDeposits);
      this.hasEnteredCheck();
    },
    hasEnteredCheck() {
      // make sure the checks at least have an amount entered
      const allAmountsEntered = !this.checkDeposits.find(
        (item) => item.Amount === null
      );
      this.$emit("setSelected", allAmountsEntered ? "HasEnteredCheck" : "");
    },
    addItem() {
      const defaultItem = this.getDefaultItem();
      const checkDeposits = this.checkDeposits;
      checkDeposits.push({ ...defaultItem });
      this.$store.dispatch("setCheckDeposits", checkDeposits);
      this.hasEnteredCheck();
    },
    removeItem(key) {
      const checkDeposits = this.checkDeposits;
      const index = checkDeposits.findIndex((item) => item.key === key);
      checkDeposits.splice(index, 1);
      this.$store.dispatch("setCheckDeposits", checkDeposits);
      this.hasEnteredCheck();
    },
    generateKey() {
      // need a random key for each item
      return Math.floor(Math.random() * 10000000);
    },
    getDefaultItem() {
      return {
        key: this.generateKey(),
        CheckNumber: null,
        Date: null,
        From: null,
        Amount: null,
      };
    },
  },
  mounted() {
    // start with one item ready to be filled in
    if (this.checkDeposits.length === 0) this.addItem();
  },
};
</script>

<style lang="scss" scoped>
#app .v-text-field {
  &::v-deep {
    .v-text-field__slot {
      padding-left: 0.25em;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
.date-input,
.currency {
  &::v-deep {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>