<template>
    <div class="approval">
      <div
        class="types"
        v-for="(tasksForType, type) in tasksByType"
        :key="type"
      >
        <div v-if="type === 'Send Letter Approval'">
          <div v-for="task in tasks" :key="task.TaskId">
            <splitHeader :fields="letterHeaderFields(task)" />
            <actionSelectionBox>
              <div class="action-select">
                <p class="subtitle-2">Select an action</p>
                <radioGroup @input="setActionForLetterTask($event, task.TaskId)" required>
                  <radio
                    v-for="action in actions"
                    :key="action"
                    :label="action"
                    :value="action"
                  />
                </radioGroup>
              </div>
            </actionSelectionBox>
            <div class = "letter">
              <div class="heading">
                <div class="heading">
                  <h5>
                    {{ task.Detail.Subject }}
                  </h5>
                  <v-menu offset-y zIndex="101">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="downloadMessage(task.Detail.LinkReference)">
                        <v-list-item-title> Download </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <tag v-if="task.Detail.TransmissionStatus">{{
                    task.Detail.TransmissionStatus
                  }}</tag>
                </div>
                <div class = "letter-date">
                  {{ formatDateTime(task.Detail.SaveDate) }}
                </div>
              </div>
              <subHeaderFields :fields="subHeaderFields(task)" />
              <div class="message-body" v-html="task.Detail.Body" />
            </div>
          </div>
        </div>
        <div v-else>
          <splitHeader :fields="headerFields(tasksForType)" />
          <disputesTable
            :actions="actions"
            :claimId="claimId"
            :columns="tableColumns"
            :items="tasksForType"
            :isLoading="isLoading"
            showSelect
          />
        </div>
      </div>
    </div>
  </template>
          
  <script>
  import { formatDateTime, formatAmount } from "@/util/format";
  import { mapGetters } from "vuex";
  
  import disputesTable from "./disputes-table";
  import actionSelectionBox from "./action-selection-box";
  import radio from "@/qux-common/qux22/components/molecules/radio";
  import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
  import splitHeader from "@/components/split-header";
  import subHeaderFields from "@/components/sub-header-fields";
  import tag from "@/qux-common/qux22/components/molecules/tag";
  
  export default {
    components: { disputesTable, splitHeader, actionSelectionBox, radio, radioGroup, subHeaderFields, tag },
    props: {
      actions: {
        type: Array,
      },
      claimId: {
        type: String,
      },
      tasks: {
        type: Array,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            text: "Dispute ID",
            value: "DisputeId",
          },
          {
            text: "Amount",
            value: "Detail.Amount",
          },
          {
            text: "Action",
            value: "action",
            sortable: false,
          },
          {
            align: "end",
            value: "menu",
            sortable: false,
          },
        ],
      };
    },
    computed: {
      tasksByType() {
        if (!this.tasks) return [];
        if (!this.tasks.length) return [];
  
        const tasksByType = {};
  
        this.tasks.forEach((task) => {
          const type = task.Detail.ApprovalType;
          if (!tasksByType[type]) {
            tasksByType[type] = [];
          }
          tasksByType[type].push(task);
        });
  
        return tasksByType;
      },
      ...mapGetters({
        attachment: "getAttachment",
      }),
      messageDownload() {
        return `data:application/octet-stream;charset=utf-8;base64,${this.attachment.DocumentStream}`;
      },
    },
    methods: {
      formatAmount(amount) {
        return formatAmount(amount);
      },
      formatDateTime(date){
        return formatDateTime(date);
      },
      headerFields(tasks) {
        const { ApprovalType } = tasks[0].Detail;

          let amount = 0;
          amount = tasks.reduce((accumulator, task) => {
            return Number(accumulator) + Number(task.Detail.Amount);
          }, 0);

        return [ApprovalType, formatAmount(amount)];
  
      },
      letterHeaderFields(task) {
        return [ task.Detail.ApprovalType, task.Detail.LetterType];
      },
      async setActionForLetterTask(action, taskId){
        await this.$store.dispatch("setTaskAction", {
          action,
          TaskId: taskId
        });
      },
      async setActionForTasks(action) {
        this.tasks.forEach(async (task) => {
          await this.$store.dispatch("setTaskAction", {
            action,
            TaskId: task.TaskId,
          });
        });
      },
      subHeaderFields(task) {
        return [
          { label: "To", data: task.Detail.To },
          { label: "From", data: task.Detail.From },
          { label: "Channel", data: task.Detail.Channel },
        ]
      },
      downloadMessage(corrKey) {
        this.$store.dispatch("retrieveAttachment", corrKey).then(() => {
          var downloadLink = document.createElement("a");
          downloadLink.href = this.messageDownload;
          downloadLink.download = this.attachment.AttachmentName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      },
    },
  };
  </script>
          
  <style lang="scss" scoped>
  .approval {
    display: flex;
    flex-direction: column;
    gap: $qvo-spacing-24;
  }
  .types {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
    
  .action-select {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .heading {
    display: flex;
    gap: $qvo-spacing-8;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
  }

  .letter {
    margin-top: 12px;
    margin-bottom: $qvo-spacing-24;
  }

  @import "@/design/confirmation-message";

  </style>