<template>
  <div>
    <v-menu offset-y zIndex="100" v-if="!isExternalUser">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="openTransactionDetails(item)">
          <v-list-item-title> Transaction Details </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openRecoveryDetails(item)">
          <v-list-item-title> View Recovery </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openAppliedMerchantCredits(item)">
          <v-list-item-title> View Applied Merchant Credits </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <transactionsDetailsModal
      :isOpen="transactionDetailsModal.isOpen"
      :ClaimId="claimId"
      :DisputeId="transactionDetailsModal.DisputeId"
      @closeModal="transactionDetailsModal.isOpen = false"
    />
  </div>
</template>
          
<script>
import { mapGetters } from "vuex";

import transactionsDetailsModal from "@/components/transactions-details-modal";

export default {
  components: { transactionsDetailsModal },
  props: {
    claimId: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      transactionDetailsModal: {
        DisputeId: null,
        id: null,
        isOpen: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isExternalUser: "getIsExternalUser",
    }),
  },
  methods: {
    openAppliedMerchantCredits(item) {
      this.$store.dispatch("showAppliedMerchantCredits", {
        DisputeId: item.DisputeId,
      });
    },
    openRecoveryDetails(item) {
      this.$store.dispatch("showRecoveryDetails", {
        DisputeId: item.DisputeId,
      });
    },
    openTransactionDetails(item) {
      this.transactionDetailsModal.DisputeId = item.DisputeId;
      this.transactionDetailsModal.isOpen = true;
    },
  },
};
</script>
          
<style lang="scss" scoped>
</style>