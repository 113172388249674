<template>
  <div class="final-investigation">
    <actionSelectionBox>
      <div class="first-row-inputs">
        <div class="action-select">
          <p class="subtitle-2">Select an action for the following disputes</p>
          <radioGroup
            v-model="selectedAction"
            @input="setActionForDisputes"
            required
          >
            <radio
              v-for="action in actions"
              :key="action"
              :label="action"
              :value="action"
            />
          </radioGroup>
        </div>
        <dropdown
          v-if="selectedAction === 'Deny'"
          v-model="selectedDenyReason"
          @change="updateDenyReason"
          placeholder="Select denial reason"
          label="Please select an appropriate denial reason from the following list"
          :items="denyReasonsDropdownItems"
        />
        <dropdown
          v-if="denyJustificationRequired"
          v-model="selectedJustifications"
          @change="updateDenyJustification"
          :placeholder="`Select ${
            denyJustificationRequiredCount === 1
              ? 'justification'
              : 'justifications'
          }`"
          :label="`Please select ${denyJustificationRequiredCount} ${
            denyJustificationRequiredCount === 1
              ? 'justification'
              : 'justifications'
          } from the following list`"
          :items="denyJustificationsDropdownItems"
          multiple
        />
        <dropdown
          v-if="selectedAction === 'Write Off'"
          v-model="selectedWriteOffReasons"
          @change="updateWriteOffReason"
          placeholder="Select write off reason"
          label="Please select an appropriate reason from the following list"
          :items="writeOffReasonsDropdownItems"
          multiple
        />
      </div>
      <textInput
        v-if="selectedAction === 'Deny' && isDenialCommentaryRequired"
        multiline
        label="Please provide commentary to support your denial decision."
        @change="updateDenyCommentary"
      />
    </actionSelectionBox>
    <h6>Disputes</h6>
    <disputesTable
      :actions="actions"
      :claimId="claimId"
      :columns="tableColumns"
      :items="tasks"
      :isLoading="isLoading"
    />
  </div>
</template>
            
  <script>
import { formatAmount } from "@/util/format";

import actionSelectionBox from "./action-selection-box";
import disputesTable from "./disputes-table";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
import textInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    actionSelectionBox,
    disputesTable,
    dropdown,
    radio,
    radioGroup,
    textInput,
  },
  props: {
    actions: {
      type: Array,
    },
    claimId: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAction: undefined,
      selectedDenyReason: undefined,
      selectedJustifications: [],
      selectedWriteOffReasons: [],
      tableColumns: [
        {
          text: "Dispute ID",
          value: "DisputeId",
        },
        {
          text: "Transaction Date",
          value: "Detail.TransactionDate",
        },
        {
          text: "Transaction Description",
          value: "Detail.TransactionDescription",
        },
        {
          text: "Dispute Amount",
          value: "Detail.DisputeAmount",
        },
        {
          text: "Recovered Amount",
          value: "Detail.RecoveredAmount",
        },
        {
          text: "Loss Potential",
          value: "Detail.LossPotentialAmount",
        },
        {
          align: "end",
          value: "menu",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isDenialCommentaryRequired() {
      return this.tasks[0].Detail.IsDenialCommentaryRequired;
    },
    denyReasons() {
      return this.tasks[0].Detail.DenyReasonList;
    },
    denyReasonsDropdownItems() {
      if (!this.denyReasons || !this.denyReasons.length) return [];
      return this.denyReasons.map((reason) => ({
        text: reason.Value,
        value: reason.Key,
      }));
    },
    denyJustifications() {
      return this.tasks[0].Detail.DenyJustificationList;
    },
    denyJustificationsDropdownItems() {
      if (!this.denyJustifications || !this.denyJustifications.length)
        return [];
      return this.denyJustifications.map((reason) => ({
        text: reason.Value,
        value: reason.Key,
      }));
    },
    denyJustificationRequired() {
      if (!this.selectedDenyReason) return false;

      const reason = this.denyReasons.find(
        (reason) => reason.Key === this.selectedDenyReason
      );

      return reason.AreJustificationsRequired || false;
    },
    denyJustificationRequiredCount() {
      return this.tasks[0].Detail.RequiredNumberOfDenyJustifications;
    },
    writeOffReasons() {
      return this.tasks[0].Detail.PaymentJustificationList;
    },
    writeOffReasonsDropdownItems() {
      if (!this.writeOffReasons || !this.writeOffReasons.length) return [];
      return this.writeOffReasons.map((reason) => ({
        text: reason.Value || reason.Key,
        value: reason.Key,
      }));
    },
    writeOffJustificationRequiredCount() {
      return this.tasks[0].Detail.RequiredNumberOfPaymentJustifications;
    },
  },
  methods: {
    formatAmount(amount) {
      return formatAmount(amount);
    },
    async setActionForDisputes(action) {
      if (action !== "Deny") {
        this.selectedDenyReason = undefined;
        this.selectedJustifications = [];
      }
      if (action !== "Write Off") {
        this.selectedWriteOffReasons = [];
      }
      this.tasks.forEach(async (dispute) => {
        await this.$store.dispatch("setTaskAction", {
          action,
          TaskId: dispute.TaskId,
        });
      });
    },
    updateDenyReason(value) {
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenyReason",
        value,
        action: this.selectedAction,
      });
    },
    updateDenyJustification(justifications) {
      const value = justifications.map((justification) => ({
        Key: justification,
      }));
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenyJustificationList",
        value,
        action: this.selectedAction,
      });
    },
    updateDenyCommentary(value) {
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "DenialCommentary",
        value,
        action: this.selectedAction,
      });
    },
    updateWriteOffReason(reasons) {
      const value = reasons.map((reason) => ({
        Key: reason,
      }));
      this.$store.dispatch("setTasksDetail", {
        ClaimId: this.claimId,
        key: "PaymentJustificationList",
        value,
        action: this.selectedAction,
      });
    },
  },
};
</script>
            
  <style lang="scss" scoped>
.final-investigation {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.reasons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action-select {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.action-selection {
  .first-row-inputs {
    display: flex;
    gap: 56px;
  }
  .dropdown-wrapper {
    &::v-deep {
      .v-select {
        width: 100%;
        max-width: 375px;
      }
    }
  }
}
</style>