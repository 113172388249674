<template>
  <viewMessage
    v-if="selectedClaim.viewMessage"
    :messageId="selectedClaim.viewMessage"
    @closeMessage="closeCorrespondence"
  />
  <div v-else>
    <h5>Communication</h5>
    <communicationDataTable @viewCorrespondence="viewCorrespondence" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import communicationDataTable from "@/components/communication-data-table";
import viewMessage from "./view-message";

export default {
  components: {
    communicationDataTable,
    viewMessage,
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/correspondence/list");
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    closeCorrespondence() {
      this.$store.dispatch("setOpenMessage", {
        ClaimId: this.selectedClaim.data.ClaimId,
        messageId: undefined,
      });
    },
    viewCorrespondence(item) {
      this.$store.dispatch("setOpenMessage", {
        ClaimId: this.selectedClaim.data.ClaimId,
        messageId: item.LinkReference,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin-bottom: $qvo-spacing-24;
}
</style>