<template>
  <div class="notification">
    <actionSelectionBox>
      <div class="action-select">
        <p class="subtitle-2">Select an action</p>
        <radioGroup
          v-model="selectedAction"
          @input="setActionForTasks"
          required
        >
          <radio
            v-for="action in actions"
            :key="action"
            :label="action"
            :value="action"
          />
        </radioGroup>
      </div>
      <textInput
        v-if="selectedAction === 'Cancel'"
        multiline
        label="Please provide a reason for cancelling this notification."
        @change="updateCancelReason"
      />
    </actionSelectionBox>
    <div class="tasks" v-for="task in tasks" :key="task.TaskId">
      <div class="task-heading">
        <subHeaderFields
          :fields="[
            { label: 'Communication Type', data: task.Detail.Purpose },
            { label: 'Communication Channel', data: task.Detail.Channel },
          ]"
        />
        <qvoButton text @click="downloadMessage(task.Detail.LinkReference)">
          <v-icon>{{
            task.Detail.Channel === "Mail" ? "$pdf" : "mdi-email-outline"
          }}</v-icon>
        </qvoButton>
      </div>
      <div
        v-if="task.Detail.Body"
        class="message-body"
        v-html="task.Detail.Body"
      />
      <viewAttachment
        v-else
        :attachment="attachment"
        @mounted="getAttachment(task.Detail.LinkReference)"
      />
    </div>
  </div>
</template>
              
  <script>
import { mapGetters } from "vuex";

import actionSelectionBox from "./action-selection-box";
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import subHeaderFields from "@/components/sub-header-fields";
import viewAttachment from "@/components/view-attachment";

export default {
  components: {
    actionSelectionBox,
    radio,
    radioGroup,
    textInput,
    qvoButton,
    subHeaderFields,
    viewAttachment,
  },
  props: {
    actions: {
      type: Array,
    },
    claimId: {
      type: String,
    },
    tasks: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAction: undefined,
      tableColumns: [
        {
          text: "Communication Type",
          value: "Detail.Purpose",
        },
        {
          text: "Communication Channel",
          value: "Detail.Channel",
        },
        {
          value: "preview",
          sortable: false,
        },
        {
          value: "icon",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    messageDownload() {
      return `data:application/octet-stream;charset=utf-8;base64,${this.attachment.DocumentStream}`;
    },
    ...mapGetters({
      attachment: "getAttachment",
    }),
  },
  methods: {
    downloadMessage(corrKey) {
      this.$store.dispatch("retrieveAttachment", corrKey).then(() => {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.messageDownload;
        downloadLink.download = this.attachment.AttachmentName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    },
    getAttachment(corrKey) {
      this.$store.dispatch("retrieveAttachment", corrKey)
    },
    async setActionForTasks(action) {
      this.tasks.forEach(async (task) => {
        await this.$store.dispatch("setTaskAction", {
          action,
          TaskId: task.TaskId,
        });
      });
    },
    updateCancelReason(value) {
      this.$store.dispatch("setTasksDetail", {
        key: "LetterCancelledCommentary",
        value,
        action: this.selectedAction,
      });
    },
  },
};
</script>
              
<style lang="scss" scoped>
.notification {
  display: flex;
  flex-direction: column;
  gap: $qvo-spacing-24;
}
.action-select {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.task-heading {
  display: flex;
}

@import "@/design/confirmation-message";
</style>