<template>
  <div>
    <transactionSelection
      v-if="currentStep.name === 'transactionSelection'"
      :shouldRefreshTxns="shouldRefreshTxns"
      @setShouldRefreshTxns="setShouldRefreshTxns"
    />
    <requiredDocs v-else-if="currentStep.name === 'requiredDocs'" />
    <questionnaire v-else-if="currentStep.name === 'questionnaire'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import questionnaire from "./detail/questionnaire";
import requiredDocs from "./detail/required-docs";
import transactionSelection from "./detail/transaction-selection";

export default {
  components: {
    questionnaire,
    requiredDocs,
    transactionSelection,
  },
  data() {
    return {
      shouldRefreshTxns: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    setShouldRefreshTxns(value) {
      this.shouldRefreshTxns = value;
    },
  },
};
</script>