<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    title="Decline Approval"
    @click:outside="closeModal"
  >
    <template #buttons>
      <qvoButton text @click="closeModal">Cancel</qvoButton>
      <qvoButton @click="save">Apply</qvoButton>
    </template>
    <div class="content">
      <textInput
        v-model="commentary"
        label="Please provide commentary to support your denial decision"
        multiline
      />
    </div>
  </modal>
</template>
      
<script>
import modal from "@/qux-common/qux22/components/molecules/modal";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    modal,
    textInput,
    qvoButton,
  },
  props: {
    action: {
      type: String,
    },
    ClaimId: {
      type: String,
    },
    detailValue: {
      type: String,
    },
  },
  data() {
    return {
      commentary: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async save() {
      await this.$store.dispatch("setTasksDetail", {
        ClaimId: this.ClaimId,
        key: this.detailValue,
        value: this.commentary,
        action: this.action,
      });
      this.$emit("closeModal");
      this.$emit("save");
    },
  },
};
</script>
      
  <style lang="scss" scoped>
</style>