<template>
  <viewAttachment
    v-if="selectedClaim.viewAttachment"
    :attachmentId="selectedClaim.viewAttachment"
    @closeAttachment="closeAttachment"
  />
  <div v-else>
    <h5>Files</h5>
    <attachmentsListTable @viewAttachment="viewAttachment" />
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import attachmentsListTable from "@/components/attachments-list-table";
import viewAttachment from "./view-attachment";

export default {
  components: {
    attachmentsListTable,
    viewAttachment,
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/attachments/list");
    },
    ...mapGetters({
      selectedClaim: "getSelectedClaim",
      pendingCalls: "getPendingCallList",
    }),
  },
  methods: {
    closeAttachment() {
      this.$store.dispatch("setOpenAttachment", {
        ClaimId: this.selectedClaim.data.ClaimId,
        attachmentId: undefined,
      });
    },
    viewAttachment(item) {
      this.$store.dispatch("setOpenAttachment", {
        ClaimId: this.selectedClaim.data.ClaimId,
        attachmentId: item.LinkReference,
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
h5 {
  margin-bottom: $qvo-spacing-24;
}
</style>