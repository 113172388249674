<template>
  <v-radio-group v-bind="$attrs" v-on="$listeners" ref="radioGroup" @change="change">
    <div class="label">
      <p v-if="label" class="subtitle-2">{{ label }}</p>
      <v-icon v-if="labelIcon" class="icon" size="16">{{ labelIcon }}</v-icon>
      <slot name="append-label" />
    </div>
    <div class="radio-fields">
      <slot />
    </div>
    <template v-slot:message="{ message }">
      <p v-if="message" class="body-2 message">{{ message }}</p>
    </template>
  </v-radio-group>
</template>

<script>
/**
 * @fileoverview Common component for radio groups
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "RadioGroup",
  props: {
    label: {
      type: String,
    },
    labelIcon: {
      type: String,
    },
  },
  methods: {
    validate() {
      return this.$refs.radioGroup.validate();
    },
    change(value) {
      this.$emit('input', value);
    }
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-left: 34px;
  color: $qvo-color-red-300;
}
.label {
  display: flex;
  align-items: center;
  gap: $qvo-spacing-8; 
  margin-bottom: $input-label-spacing;
}
.v-input {
  margin-top: 0;
  padding-top: 0;
  &::v-deep {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-messages {
      min-height: unset;
    }
  }
}
</style>