<template>
    <alert
    v-if="format === 'Notification' || format === 'Warning'"
    inline
    :message="question"
    :type="alertType"
  />
  <assistBox v-else>
    <p v-if="displayReasonPrefixText">Reason For Dispute:</p>
    <p>{{ question }}</p>
  </assistBox>
</template>

<script>
import alert from "@/qux-common/qux22/components/molecules/alert";
import assistBox from "@/components/assist-box";

export default {
  components: {
    alert,
    assistBox,
  },
  props: {
    format: {
      type: String,
    },
    question: {
      type: String,
    },
    displayReasonPrefixText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alertType() {
      if (this.format === "Warning") return "warning";
      return "info";
    },
  },
  created() {
    this.$emit("created");
  },
};
</script>

<style lang="scss" scoped>
.assist-box {
  flex-direction: row;
  align-items: center;
  p {
    margin-right: 1em;
  }
}
</style>