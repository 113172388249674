<template>
  <section class="reason">
    <p class="heading subtitle-1">
      We're sorry you're experiencing an issue with your account. The following
      questions will enable us to better assist you.
    </p>
    <v-form ref="form" @input="setReasonFormValidity">
      <div
        class="questions"
        v-for="question in reasons.QuestionList"
        :key="question.Identifier"
      >
        <inputControls
          :question="question"
          :answers="answers"
          @setAnswer="setReasonAnswer"
          @unsetAnswer="unsetReasonAnswer"
        />
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import inputControls from "./input-controls";

export default {
  components: {
    inputControls,
  },
  computed: {
    isQuestionsLoading() {
      return this.pendingCalls.includes("/claim/intake/retrieveReasons");
    },
    isSubmitLoading() {
      return this.pendingCalls.includes("/claim/intake/addClaimReasons");
    },
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
      pendingCalls: "getPendingCallList",
      reasons: "getReasons",
      answers: "getReasonAnswers",
    }),
  },
  methods: {
    setReasonFormValidity(value) {
      this.$store.dispatch("setReasonFormValidity", {
        value,
      });
    },
    setReasonAnswer({ questionId, answer }) {
      this.$store.dispatch("setReasonAnswer", {
        questionId,
        answer,
      });
    },
    unsetReasonAnswer({ questionId }) {
      if (this.currentStep.name === "reason") {
        // since this is automatically triggered when questions aren't visible in the UI,
        // we don't want this to run when leaving this page, otherwise all answers will unset
        this.$store.dispatch("unsetReasonAnswer", {
          questionId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reason {
  height: calc(100% - 74px);
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.heading {
  max-width: 515px;
  color: $qvo-color-grey-600;
}
</style>