<template>
  <div class="task">
    <pageTabHeader>
      <h5>{{ taskType }}</h5>
      <qvoButton text class="refresh-button" @click.stop="refreshTasks">
        <v-icon>mdi-refresh</v-icon>
      </qvoButton>
    </pageTabHeader>
    <tabContent>
      <typeTable
        ref="typeTable"
        :itemCount="itemCount"
        :itemsPerPage="50"
        :label="taskType"
        @openTask="openTask"
      />
    </tabContent>
  </div>
</template>
      
<script>
import { mapGetters } from "vuex";

import pageTabHeader from "@/components/page-tab-header";
import tabContent from "@/components/tab-content";
import typeTable from "@/components/task-type-table";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    pageTabHeader,
    tabContent,
    typeTable,
    qvoButton,
  },
  methods: {
    openTask(task) {
      const { ClaimId, TaskLabel } = task;
      this.$store.dispatch("setOpenTask", {
        ClaimId,
        TaskLabel,
      });
      this.$store.dispatch("clearTasksDetail");
    },
    refreshTasks() {
      this.$refs.typeTable.retrieveTaskList();
    },
  },
  computed: {
    itemCount() {
      return (
        this.taskSummary?.find((task) => task.TaskLabel === this.taskType)
          ?.TaskCount || 0
      );
    },
    taskType() {
      return this.$route.params.type;
    },
    ...mapGetters({
      activeClientId: "getActiveClientId",
      pendingCalls: "getPendingCallList",
      taskSummary: "getTaskSummary",
    }),
  },
};
</script>
      
<style lang="scss" scoped>
.task {
  height: 100%;
  padding-bottom: 22px;

  .refresh-button.v-btn {
    .mdi-refresh {
      color: $qvo-color-grey-1000;
    }
  }
}

.content {
  padding: 40px;
}

h4 {
  margin-bottom: $qvo-spacing-8;
}
</style>