<template>
  <v-radio
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    ref="radio"
    @mouseup="reset"
  >
    <template v-slot:label>
      <p class="subtitle-1">{{ label }}</p>
      <v-icon v-if="icon" class="icon" size="16">{{ icon }}</v-icon>
      <slot name="append-label" />
    </template>
    <template v-slot:message="{ message }">
      <p v-if="message" class="body-2 message">{{ message }}</p>
    </template>
  </v-radio>
</template>

<script>
/**
 * @fileoverview Common component for radio inputs
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Radio",
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Boolean],
    },
    icon: {
      type: String,
    },
    deselectable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    reset(event) {
      const isMainButtonClick = event.button === 0;
      if (this.deselectable && isMainButtonClick) {
        const currentValue = this.$refs.radio.radioGroup.value;
        const newValue = this.$refs.radio.value;
        if (currentValue === newValue) {
          this.$nextTick(() => {
            this.$refs.radio.radioGroup.$emit("change", null);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-radio {
  .v-icon.icon {
    margin-left: $qvo-spacing-8;
    color: $qvo-color-blue-400;
  }
  &::v-deep {
    // increased specificity so that the default table styles don't override radio button styles
    .v-input--selection-controls__input .v-icon.v-icon.v-icon {
      color: $qvo-color-grey-500;
      &.mdi-radiobox-marked {
        color: $qvo-color-blue-400;
      }
      &.error--text {
        color: $qvo-color-red-300;
      }
    }
    .v-input--selection-controls__input:hover,
    .v-input--selection-controls__ripple:before {
      background: none;
    }
    &:hover {
      .v-icon {
        color: $qvo-color-blue-400;
      }
    }
  }
  &.v-radio--is-disabled {
    &::v-deep {
      .v-icon {
        color: $qvo-color-grey-200 !important; // Vuetify uses !important here to set the disabled color 🤦
      }
      .subtitle-1 {
        color: $qvo-color-grey-200;
      }
    }
  }
}
</style>