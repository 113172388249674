<template>
  <section class="case-header">
    <h1>{{title}}</h1>
    <caseIdentifiers :claim="claim" v-if="!isLoading" />
  </section>
</template>

<script>
/**
 * @fileoverview Case header
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import caseIdentifiers from "@/components/case-identifiers";

export default {
  components: {
    caseIdentifiers,
  },
  props: {
    claim: {
      type: Object,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.case-header {
  display: flex;
  align-items: center;
}
</style>