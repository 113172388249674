<template>
  <div class="search">
    <pageTabHeader>
      <h5>Home</h5>
      <qvoButton @click.stop="viewCreateClaim">Create Claim</qvoButton>
    </pageTabHeader>
    <tabContent>
      <h4>Claim Search</h4>
      <p class="subtitle-2">
        Find an exact claim by ID, or lookup all claims belonging to an account,
        or individual.
      </p>
      <claimDataTable @viewCase="viewCase" />
    </tabContent>
  </div>
</template>
  
  <script>
import claimDataTable from "@/components/claim-data-table";
import pageTabHeader from "@/components/page-tab-header";
import qvoButton from "@/qux-common/qux22/components/molecules/button";
import tabContent from "@/components/tab-content";

export default {
  components: {
    claimDataTable,
    pageTabHeader,
    qvoButton,
    tabContent,
  },
  methods: {
    /**
     * View the specified case
     * @param {Object} claim - claim to be viewed
     */
    viewCase(claim) {
      this.$router.push(`/case-view/${claim.claimId}`);
    },
    viewCreateClaim() {
      this.$store.dispatch("navigateToIntake");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.search {
  height: calc(100% - #{$page-tab-height} + 20px);
}

.content {
  padding: 40px;
}

h4 {
  margin-bottom: $qvo-spacing-8;
}
</style>