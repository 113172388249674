<template>
    <v-tooltip right max-width="20em" v-if="text">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="icon"
          >mdi-information-outline</v-icon
        >
      </template>
      {{ text }}
    </v-tooltip>
  </template>
    
  <script>
  /**
   * @fileoverview Tooltip
   *
   * @author Cameron Bulock <cameron.bulock@quavo.com>
   */
  
  export default {
    name: "Tooltip",
    props: {
      text: {
        type: String,
      },
    },
  };
  </script>
    
  <style lang="scss" scoped>
  .icon {
    color: $qvo-color-blue-400;
  }
  .v-tooltip__content {
    color: $qvo-color-grey-000;
  }
  </style>