<template>
  <section class="case-identifiers">
    <labeledIcon
      v-if="claim && claim.ClaimId"
      icon="mdi-file-outline"
      :label="claim.ClaimId"
    />
    <labeledIcon
      v-if="claim && claim.AccountHolder"
      icon="mdi-account-outline"
      :label="claim.AccountHolder"
    />
    <labeledIcon
      v-if="claim && claim.lastFour"
      :icon="
        claim.AccountType === 'CreditCard'
          ? 'mdi-credit-card-outline'
          : 'mdi-wallet-outline'
      "
      :label="`x${claim.lastFour}`"
    />
  </section>
</template>

<script>
import labeledIcon from "@/components/labeled-icon";
/**
 * @fileoverview Case identifiers to display in case header
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

export default {
  components: { labeledIcon },
  props: {
    claim: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.case-identifiers {
  display: flex;
  gap: 12px;
}
</style>