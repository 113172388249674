<template>
  <div>
    <modal
      :value="isOpen"
      v-bind="$attrs"
      v-on="$listeners"
      title="Transaction Disputes"
      @closeModal="closeModal"
    >
      <div class="details">
        <disputedTransactions />
      </div>
      <template #buttons>
        <qvoButton @click="closeModal">Close</qvoButton>
      </template>
    </modal>
  </div>
</template>
      
  <script>
/**
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import disputedTransactions from "@/components/disputed-transactions";
import modal from "@/components/modal";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: { disputedTransactions, modal, qvoButton },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
      
<style lang="scss" scoped>
</style>