<template>
  <modal v-model="modal" title="Document Upload">
    <template #activator="{ on, attrs }">
      <qvoButton
        v-bind="attrs"
        v-on="on"
        :disabled="isLoading"
        :leftIcon="leftIcon"
        :outlined="outlined"
        >{{
          labelOverride ||
          (isLoading ? `Uploading ${label}` : `Upload ${label}`)
        }}</qvoButton
      >
    </template>
    <template #buttons>
      <qvoButton text @click="closeModal">Cancel</qvoButton>
      <qvoButton @click="saveUpload">Attach File</qvoButton>
    </template>
    <v-file-input
      ref="fileInput"
      :value="file"
      @change="addFile"
      v-show="false"
    />
    <div class="content">
      <p class="subtitle-1">Upload document</p>
      <fileDisplay
        v-if="hasFile"
        :fileSource="getFile.file"
        :fileHeader="getFile.header"
      />
      <div
        v-else
        class="drop-area"
        :class="readyToDrop && 'ready-to-drop'"
        @drop.prevent="addFileFromDrag"
        @dragover.prevent
        @dragenter="readyToDrop = true"
        @dragleave.prevent.self="readyToDrop = false"
        @click="openFilePicker"
      >
        <div class="drop-message">
          <v-icon size="128">mdi-upload-outline</v-icon>
          <p class="subtitle-1">Drag and drop your document here or</p>
          <qvoButton outlined>Choose File</qvoButton>
        </div>
      </div>
      <div v-if="file" class="file-info">
        <div class="data">
          <p class="subtitle-1 file-name">{{ getFile.name }}</p>
          <p class="subtitle-1 file-size">{{ getFile.formattedSize }}</p>
        </div>
        <div class="buttons">
          <qvoButton text :href="fileDownload" :download="filename">
            <v-icon>mdi-download</v-icon>
          </qvoButton>
          <qvoButton text @click="removeFile">
            <v-icon>mdi-delete-outline</v-icon>
          </qvoButton>
        </div>
      </div>
      <textInput v-model="filename" label="Filename" />
    </div>
  </modal>
</template>
    
<script>
import { formatBytes } from "@/util/format";
import readFile from "@/util/readFileAsync";

import fileDisplay from "./file-display";
import modal from "@/qux-common/qux22/components/molecules/modal";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    fileDisplay,
    modal,
    textInput,
    qvoButton,
  },
  props: {
    label: {
      type: String,
    },
    labelOverride: {
      type: String,
    },
    leftIcon: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
      file: undefined,
      filename: "",
      category: "SupportingDocumentation",
      readyToDrop: false,
    };
  },
  methods: {
    closeModal() {
      this.removeFile();
      this.filename = "";
      this.modal = false;
    },
    saveUpload() {
      this.$emit("attachFile", {
        category: this.category,
        file: this.file,
        name: this.filename,
      });
      this.closeModal();
    },
    addFile(file) {
      this.file = file;
    },
    addFileFromDrag(event) {
      this.readyToDrop = false;
      const { files } = event.dataTransfer;
      this.addFile(files[0]);
    },
    removeFile() {
      this.file = undefined;
    },
    openFilePicker() {
      this.$refs.fileInput.$refs.input.click();
    },
  },
  computed: {
    hasFile() {
      return !!this.file;
    },
    fileDownload() {
      return URL.createObjectURL(this.file);
    },
  },
  asyncComputed: {
    async getFile() {
      if (this.file) {
        const { name, size } = this.file;
        const fileExtensionIndex = name.lastIndexOf(".");
        const basename = name.slice(0, fileExtensionIndex);
        const extension = name.slice(fileExtensionIndex + 1);

        const file = await readFile(this.file);
        const [header, data] = file.split(",");
        const formattedSize = formatBytes(size);

        return { file, header, data, name, basename, extension, formattedSize };
      }
      return {};
    },
  },
  watch: {
    getFile() {
      this.filename = this.getFile.basename;
    },
  },
};
</script>
    
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: $qvo-spacing-16;
  p {
    color: $qvo-color-grey-700;
  }
  .v-icon {
    color: $qvo-color-blue-400;
  }
}
.drop-area {
  flex-grow: 1;
  border: 2px dashed $qvo-color-grey-400;
  border-radius: 8px;
  padding: $qvo-spacing-24;
  transition: background 300ms ease-in;
  p {
    color: $qvo-color-grey-500;
  }
  .drop-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    p {
      margin-bottom: $qvo-spacing-24;
    }
  }
}
.ready-to-drop {
  background: $qvo-color-blue-200;
}
.file-display {
  margin: 0 auto;
}
.file-info {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid $qvo-color-grey-400;
  border-radius: 8px;
  .data {
    display: flex;
    flex-direction: column;
    .file-name {
      color: $qvo-color-grey-900;
    }
    .file-size {
      color: $qvo-color-grey-600;
    }
  }
  .buttons {
    display: flex;
    gap: $qvo-spacing-16;
    .v-btn.v-size--default {
      min-width: unset;
      padding: 0;
      &::v-deep {
        .v-icon {
          color: $qvo-color-grey-500;
        }
      }
    }
  }
}
</style>