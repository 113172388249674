<template>
  <div>
    <accountSelect v-if="currentStep.name === 'accountSelect'" />
    <accountHolderSearch v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import accountHolderSearch from "./account/accountHolderSearch";
import accountSelect from "./account/accountSelect";

export default {
  components: {
    accountHolderSearch,
    accountSelect,
  },
  computed: {
    ...mapGetters({
      currentStep: "getIntakeNavigationCurrentStep",
    }),
  },
};
</script>