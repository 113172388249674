<template>
  <div class="tabs-wrapper">
    <v-tabs
      @change="(event) => $emit('input', event)"
      v-bind="$attrs"
      v-on="$listeners"
      hide-slider
      show-arrows
      center-active
    >
      <v-tab
        v-for="item in items"
        :key="item.href"
        :href="item.href"
        :to="item.to"
      >
        <v-tooltip
          right
          max-width="20em"
          v-if="item.leftIcon && item.leftIconTooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="left-icon">
              {{ item.leftIcon }}
            </v-icon>
          </template>
          {{ item.leftIconTooltip }}
        </v-tooltip>
        <v-icon v-else-if="item.leftIcon" class="left-icon">
          {{ item.leftIcon }}
        </v-icon>

        <v-skeleton-loader v-if="item.isLoading" type="text" />
        <p v-else class="subtitle-2">{{ item.name }}</p>
        <v-icon
          v-if="!item.hideCloseButton"
          @click.prevent="closeTab(item)"
          class="close-icon"
        >
          mdi-close
        </v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items><slot /></v-tabs-items>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for tabs
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

export default {
  name: "Tabs",
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    closeTab(item) {
      this.$emit("closeTab", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
  box-shadow: $qvo-shadow-2;
}
.v-tabs {
  &::v-deep {
    .v-slide-group__content {
      background: $qvo-color-blue-100;
      .v-tab--active {
        background: $qvo-color-grey-000;
        box-shadow: $qvo-shadow-2;
        border-radius: 8px 8px 0px 0px;
        &:first-child {
          border-radius: 0px 8px 0px 0px;
        }
      }
    }
    .v-slide-group--is-overflowing {
      .v-tab--active:last-child {
        border-radius: 8px 0px 0px 0px;
      }
    }
    .v-tab {
      &.v-tab--active {
        &:hover:before {
          opacity: 0;
        }
      }
      &:before {
        border-radius: 8px 8px 0px 0px;
      }
      &:first-child:before {
        border-radius: 0px 8px 0px 0px;
      }
      &:last-child:before {
        border-radius: 8px 0px 0px 0px;
      }
    }
    .v-skeleton-loader__text {
      width: 5em;
    }
  }
}
.left-icon {
  margin-right: $qvo-spacing-8;
}
.close-icon {
  margin-left: $qvo-spacing-16;
}
.subtitle-2 {
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-tooltip__content {
  color: $qvo-color-grey-000;
}
</style>