<template>
  <div
    class="date-picker"
    :class="`${getUniqueClass} ${double && 'is-double'}`"
    ref="datePickerWrapper"
  >
    <div class="input-wrapper">
      <textInput
        :value="value || fromDate"
        v-bind="$attrs"
        v-on="$listeners"
        :width="width"
        readonly
        ref="datePicker"
        @focus="openMenu"
        @mousedown.prevent="openMenu"
        @click:prepend-inner.prevent="openMenu"
        @input="
          (v) => {
            $emit('update', v);
            $emit('update:fromDate', v);
          }
        "
        @change="
          (v) => {
            $emit('update', v);
            $emit('update:fromDate', v);
          }
        "
        prependInnerIcon="mdi-calendar-blank-outline"
      />
      <textInput
        v-if="double"
        :value="toDate"
        v-bind="$attrs"
        v-on="$listeners"
        :width="width"
        readonly
        ref="datePicker2"
        @focus="openMenu"
        @mousedown.prevent="openMenu"
        @click:prepend-inner.prevent="openMenu"
        @input="(v) => $emit('update:toDate', v)"
        @change="(v) => $emit('update:toDate', v)"
        prependInnerIcon="mdi-calendar-blank-outline"
      />
    </div>
    <v-menu
      v-model="menuOpen"
      :attach="menuAttachNode"
      :close-on-content-click="false"
      :content-class="getMenuClass"
      :position-y="scroll.offset || -verticalObstruction"
      bottom
      eager
      nudge-bottom="80"
    >
      <div class="date-picker-content" ref="datePickerContent" />
      <div class="buttons">
        <qvoButton text @click="clearDate">Clear</qvoButton>
        <qvoButton @click="closeMenu">Done</qvoButton>
      </div>
    </v-menu>
  </div>
</template>
      
<script>
/**
 * @fileoverview Date picker component
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import {
  getScrollParent,
  observeMenu,
  getMenuClass,
  getUniqueClass,
} from "../../utils/menu-helpers";

import textInput from "../../molecules/input";
import qvoButton from "../../molecules/button";

export default {
  name: "DatePicker",
  components: {
    textInput,
    qvoButton,
  },
  props: {
    double: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    fromMax: {
      type: String,
    },
    fromMin: {
      type: String,
    },
    toMax: {
      type: String,
    },
    toMin: {
      type: String,
    },
    width: {
      type: String,
      default: "13ch",
    },
    value: {
      type: String,
    },
    toDate: {
      type: String,
    },
    fromDate: {
      type: String,
    },
  },
  data() {
    return {
      fpInstance: null,
      fpInstance2: null,
      menuAttachNode: null,
      menuOpen: false,
      intersectionObserver: null,
      mutationObserver: null,
      scroll: {
        parent: null,
        start: null,
        offset: null,
      },
      verticalObstruction: 0,
      menuHeight: "auto",
    };
  },
  computed: {
    flatpickrConfig() {
      return {
        altFormat: "m/d/Y",
        altInput: true,
        dateFormat: "Y-m-d",
        inline: true,
        prevArrow:
          "<i aria-hidden='true' class='v-icon notranslate mdi mdi-chevron-left theme--light'></i>",
        nextArrow:
          "<i aria-hidden='true' class='v-icon notranslate mdi mdi-chevron-right theme--light'></i>",
      };
    },
    getMenuClass() {
      return getMenuClass();
    },
    getUniqueClass() {
      // need a random id for each instance of this component
      return getUniqueClass();
    },
  },
  methods: {
    datePickerEl() {
      return this.$refs.datePicker && this.$refs.datePicker.$el;
    },
    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    clearDate() {
      if (this.fpInstance) this.fpInstance.clear();
      if (this.fpInstance2) this.fpInstance2.clear();
    },
    initFlatpickr(config = this.flatpickrConfig) {
      flatpickr.l10ns.default.weekdays.shorthand = [
        "S",
        "M",
        "T",
        "W",
        "T",
        "F",
        "S",
      ];

      // if (!this.menuOpen) return;
      if (this.fpInstance) {
        if (this.fpInstance.isOpen) return;
        this.fpInstance.destroy();
      }
      this.fpInstance = flatpickr(
        this.$refs.datePicker.$refs.input.$refs.input,
        {
          ...config,
          appendTo: this.$refs.datePickerContent,
          minDate: this.min || this.fromMin,
          maxDate: this.max || this.fromMax,
        }
      );
      if (this.double) {
        if (this.fpInstance2) {
          if (this.fpInstance2.isOpen) return;
          this.fpInstance2.destroy();
        }
        this.fpInstance2 = flatpickr(
          this.$refs.datePicker2.$refs.input.$refs.input,
          {
            ...config,
            appendTo: this.$refs.datePickerContent,
            minDate: this.toMin,
            maxDate: this.toMax,
          }
        );
      }
    },
    onScroll() {
      this.scroll.offset = this.scroll.start - this.scroll.parent.scrollTop;
    },
    observeMenu() {
      const {
        mutationObserver,
        intersectionObserver,
        verticalObstruction,
        menuHeight,
      } = observeMenu({
        menuClass: this.getMenuClass,
        menuHeight: this.menuHeight,
      });
      this.mutationObserver = mutationObserver;
      this.intersectionObserver = intersectionObserver;
      this.verticalObstruction = verticalObstruction;
      this.menuHeight = menuHeight;
    },
    disconnectObservers() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
    getScrollParent() {
      return getScrollParent({ wrapperClass: this.getUniqueClass });
    },
  },
  mounted() {
    this.menuAttachNode = this.$refs.datePicker.$el;
    this.initFlatpickr();
  },
  watch: {
    flatpickrConfig: {
      deep: true,
      handler(newConfig) {
        this.initFlatpickr(newConfig);
      },
    },
    min(value) {
      this.fpInstance.set("minDate", value);
    },
    max(value) {
      this.fpInstance.set("maxDate", value);
    },
    fromMin(value) {
      this.fpInstance.set("minDate", value);
    },
    fromMax(value) {
      this.fpInstance.set("maxDate", value);
    },
    toMin(value) {
      this.fpInstance2.set("minDate", value);
    },
    toMax(value) {
      this.fpInstance2.set("maxDate", value);
    },
  },
  beforeDestroy() {
    if (this.fpInstance) {
      this.fpInstance.destroy();
    }
    if (this.fpInstance2) {
      this.fpInstance2.destroy();
    }
  },
};
</script>

<style lang="scss">
.flatpickr-calendar {
  box-shadow: none;
  .flatpickr-months {
    > * {
      position: relative;
    }
    .flatpickr-current-month {
      display: flex;
      align-items: flex-end;
    }
    .flatpickr-month {
      order: 0;
    }
    .flatpickr-prev-month {
      order: 1;
      .v-icon {
        color: $qvo-color-grey-900;
      }
    }
    .flatpickr-next-month {
      order: 2;
      .v-icon {
        color: $qvo-color-grey-900;
      }
    }
    .flatpickr-monthDropdown-months,
    .cur-year {
      color: $qvo-color-grey-900;
      // button typography style
      font-size: 0.875rem;
      font-weight: 850;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }
}
</style>
      
<style lang="scss" scoped>
.is-double {
  .input-wrapper {
    display: flex;
    div:first-child {
      &::v-deep {
        .v-input__slot {
          border-radius: $qvo-spacing-8 0 0 $qvo-spacing-8;
          fieldset {
            border-right: 0;
          }
        }
        .v-input--is-focused .v-input__slot fieldset {
          border-right: 2px solid currentColor;
        }
      }
    }
    > div:last-child {
      display: flex;
      &:before {
        content: "";
        border-left: 1px solid $qvo-color-grey-300;
      }
      &::v-deep {
        .v-input__slot {
          border-radius: 0 $qvo-spacing-8 $qvo-spacing-8 0;
          fieldset {
            border-left: 0;
          }
        }
        .v-input--is-focused .v-input__slot fieldset {
          border-left: 2px solid currentColor;
        }
      }
    }
  }
  .date-picker-content {
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}
.buttons {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.date-picker-content {
  display: flex;
  gap: 12px;
}
.v-menu__content {
  background: $qvo-color-grey-000;
  padding: 12px;
  box-shadow: $qvo-shadow-2;
}
</style>