<template>
  <v-stepper
    alt-labels
    class="stepper"
    v-bind="$attrs"
    v-on="$listeners"
    :value="step"
    flat
  >
    <v-stepper-header>
      <template v-for="(stepData, index) in steps">
        <v-stepper-step
          :step="index + 1"
          :key="`stepper-${index}`"
          :complete="step > index + 1"
          :rules="[() => !stepData.error]"
          :editable="stepData.editable"
          edit-icon="mdi-pencil-outline"
          :class="stepData.subtitle && 'hasSubtitle'"
        >
          <p class="body-2 title">{{ stepData.title }}</p>
          <p class="caption">{{ stepData.subtitle }}</p>
        </v-stepper-step>
        <v-divider v-if="index + 1 < steps.length" :key="`divider-${index}`" />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
/**
 * @fileoverview Common component for steppers
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

export default {
  name: "Stepper",
  components: {},
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper.v-stepper.theme--light {
  .v-stepper__step {
    &::v-deep {
      .v-stepper__step__step {
        //body-1 styles
        font-size: 1rem;
        font-weight: 350;
      }
      .v-stepper__label {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        text-shadow: unset;
        .title {
          color: $qvo-color-grey-900;
          text-align: center;
        }
        .caption {
          color: $qvo-color-grey-600;
        }
      }
      .mdi-pencil-outline {
        font-size: 18px;
      }
    }
    &.v-stepper__step--complete {
      &::v-deep {
        .v-stepper__step__step {
          background: $qvo-color-blue-400;
        }
      }
    }
    &.v-stepper__step--active {
      &::v-deep {
        .v-stepper__step__step {
          background: $qvo-color-blue-400;
        }
      }
    }
    &.v-stepper__step--inactive {
      &::v-deep {
        .v-stepper__step__step {
          background: $qvo-color-grey-500;
        }
      }
    }
    &.v-stepper__step--error {
      &::v-deep {
        .v-stepper__step__step {
          color: $qvo-color-red-300;
          background: unset;
        }
        .v-stepper__label {
          .title,
          .caption {
            color: $qvo-color-red-300;
          }
        }
      }
    }
  }
  .v-divider {
    border-color: $qvo-color-grey-500;
  }
  &.v-stepper--vertical {
    width: fit-content;
    padding-bottom: 0;
    .v-stepper__header {
      flex-direction: column;
      .v-stepper__step {
        flex-direction: row;
        align-items: flex-start;
        flex-basis: 85px;
        &::v-deep {
          .v-stepper__label {
            align-items: flex-start;
            .title {
              margin-top: 3px;
            }
          }
        }
      }
      .v-divider:not(.v-stepper__header
          .v-stepper__step.hasSubtitle
          + .v-divider) {
        // dividers that follow when there isn't a subtitle (margin has to be adjusted)
        margin: -12px 18px 0;
      }
      .v-divider {
        width: 36px;
        transform: rotate(90deg);
        margin: -16px 18px 0;
      }
    }
  }
}
</style>