<template>
  <div class="v-data-table">
    <radioGroup v-model="radioValue">
      <v-data-table
        v-bind="$attrs"
        v-on="$listeners"
        :class="showSelect && 'selectable'"
        :headers="computedHeaders"
        :item-key="itemKey"
        :items="items"
        :page="localPage"
        :items-per-page="localItemsPerPage"
        :showExpand="showExpand"
        :showSelect="showSelect"
        :singleSelect="singleSelect"
        :value="value"
        hide-default-footer
        @click:row="rowSelect"
        @input="updateSelection"
      >
        <template v-slot:header.data-table-select="{ on, props }">
          <checkbox
            v-if="!singleSelect"
            v-bind="props"
            @click.stop
            @change="on.input($event)"
          />
        </template>
        <template v-slot:item.data-table-select="{ isSelected, item, select }">
          <div class="data-table-select">
            <checkbox
              v-if="!singleSelect"
              :input-value="isSelected"
              :value="isSelected"
              @click.stop
              @change="select($event)"
            />
            <radio
              v-if="singleSelect"
              :value="item[itemKey]"
              @click.stop
              @change="select(true)"
            />
          </div>
        </template>
        <template v-slot:footer>
          <div class="footer">
            <pagination
              :itemCount="itemCount"
              :itemsPerPage="localItemsPerPage"
              :page="localPage"
              @input="updatePage"
              @perPage="updateItemsPerPage"
            />
          </div>
        </template>
        <template v-for="(index, name) in $slots" v-slot:[name]>
          <slot :name="name" />
        </template>
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </v-data-table>
    </radioGroup>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for data tables
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import checkbox from "../molecules/checkbox";
import pagination from "../molecules/pagination";
import radio from "../molecules/radio";
import radioGroup from "../molecules/radio-group";

export default {
  name: "DataTable",
  components: { checkbox, pagination, radio, radioGroup },
  data() {
    return {
      localPage: this.page,
      localItemsPerPage: this.itemsPerPage,
      radioValue:
        (this.value && this.value[0] && this.value[0][this.itemKey]) || [],
    };
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    overrides: {
      type: Object,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    page() {
      if (this.overrides && this.overrides.page) return this.overrides.page;
      return 1;
    },
    itemCount() {
      if (this.overrides && this.overrides.itemCount)
        return this.overrides.itemCount;
      return this.items.length || 0;
    },
    computedHeaders() {
      const headers = [...this.headers];
      if (this.showExpand) {
        // automatically add the expansion column at the end whenever the rows are expandable
        headers.push({ text: "", value: "data-table-expand" });
      }
      return headers;
    },
  },
  methods: {
    rowSelect(item, slot) {
      if (this.showSelect) {
        const { select, isSelected } = slot;
        this.singleSelect ? select(true) : select(!isSelected);
      }
    },
    updateSelection(value) {
      if (this.singleSelect) {
        this.radioValue = value[0][this.itemKey];
      }
    },
    updatePage(page) {
      this.$emit("pageChange", { page, itemsPerPage: this.localItemsPerPage });
      this.localPage = page;
    },
    updateItemsPerPage(itemsPerPage) {
      this.$emit("pageChange", { page: this.localPage, itemsPerPage });
      this.localItemsPerPage = itemsPerPage;
    },
  },
  watch: {
    "overrides.page"(page) {
      if (page) this.localPage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table {
  border: 1px solid $qvo-color-grey-200;
  border-radius: 8px;
  &.selectable {
    cursor: pointer;
  }
  &::v-deep {
    div.v-data-table__wrapper {
      border-radius: 8px 8px 0 0;
      thead {
        background: $qvo-color-blue-100;
        tr {
          border-color: $qvo-color-grey-200;
          th {
            white-space: nowrap;
            // subtitle-1 styles
            font-size: 1rem;
            font-weight: 500;
            .v-data-table-header__icon {
              // sort icon
              vertical-align: unset;
              padding: 0 4px;
            }
          }
          td {
            // subtitle-2 styles
            font-weight: 500;
          }
        }
      }
      .v-input--selection-controls__input {
        .v-icon {
          color: $qvo-color-grey-500;
        }
      }
      tbody {
        tr > td {
          padding: 16px;
          // top align the text, but not the selection controls
          &:not(:has(.data-table-select)) {
            vertical-align: top;
          }
        }
        tr:hover:not(.v-data-table__expanded__content):not(
            .v-data-table__empty-wrapper
          ) {
          background: $qvo-color-grey-100;
        }
      }
      .v-data-table__expanded td:first-child {
        border-left: 4px solid $qvo-color-blue-400;
      }
      .v-data-table__expanded__content {
        width: 100%;
        background: $qvo-color-blue-100;
        box-shadow: none;
      }
    }
  }
  .footer {
    border-top: 1px solid $qvo-color-grey-200;
    padding: 12px 16px;
    display: flex;
    justify-content: flex-end;
    &::v-deep {
      .dropdown {
        top: 5px;
      }
      .page-data {
        position: relative;
        top: 2px;
      }
      nav {
        height: 24px;
      }
    }
  }
}
</style>