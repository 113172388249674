<template>
  <div :class="formatClass" class="base-input">
    <p
      class="subtitle-2"
      v-if="question.Type !== 'Nothing' && question.Type !== 'Questionnaire'"
    >
      {{ question.DisplayText }}
    </p>
    <autoComplete
      v-if="question.Type === 'AutoComplete'"
      :answerList="question.AnswerList"
      :value="value"
      :displayText="question.DisplayText"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
      :disabled="disabled"
    />
    <checkboxes
      v-else-if="question.Type === 'Checkboxes'"
      :answerList="question.AnswerList"
      :value="value"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
    />
    <currency
      v-else-if="question.Type === 'Currency'"
      :value="value"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
    />
    <date
      v-else-if="question.Type === 'Date'"
      :value="value"
      :displayText="question.DisplayText"
      :questionId="question.Identifier"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
      :disabled="disabled"
    />
    <dropDown
      v-else-if="question.Type === 'Dropdown'"
      :answerList="question.AnswerList"
      :value="value"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
      :disabled="disabled"
    />
    <nothing
      v-else-if="question.Type === 'Nothing'"
      :question="question.DisplayText"
      :format="question.Format"
      :displayReasonPrefixText="displayReasonPrefixText"
      @created="updateFollowupQuestions"
    />
    <questionnaire
      v-else-if="question.Type === 'Questionnaire'"
      @created="updateFollowupQuestions"
    />
    <radioButtons
      v-else-if="question.Type === 'RadioButtons' || question.Type === 'YesNo'"
      :answerList="question.AnswerList"
      :type="question.Type"
      :value="value"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
      :disabled="disabled"
    />
    <sectionInput
      v-else-if="question.Type === 'Section'"
      :answerList="question.AnswerList"
      :questionId="question.Identifier"
      @setAnswer="setAnswer"
    />
    <textInput
      v-else-if="question.Type === 'Text' || question.Type === 'TextMulti'"
      :multiline="question.Type === 'TextMulti'"
      :value="value"
      :isOptional="question.IsOptional"
      @setAnswer="setAnswer"
      :disabled="disabled"
    />
    <div v-else>
      There is a question of an unsupported type: <b>{{ question.Type }}</b>
    </div>
  </div>
</template>

<script>
import autoComplete from "./auto-complete";
import checkboxes from "./checkboxes";
import currency from "./currency";
import date from "./date";
import dropDown from "./drop-down";
import nothing from "./nothing";
import questionnaire from "./questionnaire";
import radioButtons from "./radio-buttons";
import sectionInput from "./section";
import textInput from "./text";

export default {
  components: {
    autoComplete,
    checkboxes,
    currency,
    date,
    dropDown,
    nothing,
    questionnaire,
    radioButtons,
    sectionInput,
    textInput,
  },
  computed: {
    formatClass() {
      return {
        "qvo-question-caption": this.question.Format === "Caption",
        "qvo-question-internal": this.question.Format === "Internal",
        [`${this.question.Type}-type`]: true,
      };
    },
  },
  props: {
    question: {
      type: Object,
    },
    displayReasonPrefixText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: undefined,
    },
  },
  methods: {
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      if (this.question.Identifier) {
        this.$emit("setAnswer", {
          questionId: this.question.Identifier,
          answer,
        });
      }
    },
    updateFollowupQuestions() {
      this.$emit("updateFollowupQuestions");
    },
  },
  mounted() {
    // check to see if there is a default answer for this question and set it
    if (this.question) {
      const answer = this.question.SelectedAnswer;
      if (answer !== undefined) {
        this.setAnswer(answer);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  padding-bottom: 36px;
  &.Questionnaire-type {
    // questionnaire is just a wrapper and doesn't have it's own content
    padding-bottom: 0;
  }
}
.subtitle-2 {
  padding-bottom: 12px;
}
.qvo-question-caption {
  padding: 1em 2em;
  background: $qvo-color-grey-100;
  margin: 1em 0;
  color: $qvo-color-grey-700;
  font-weight: normal;
  font-size: initial;
  border-radius: $qvo-spacing-8;
}

.qvo-question-caption div p {
  margin: 0;
}

.qvo-question-internal {
  position: relative;
  padding: $qvo-spacing-16;
  border: 2px dashed;
  border-radius: 8px;
  border-color: $qvo-color-grey-400;
  margin-bottom: 1.5em;
}

.qvo-question-internal::before {
  content: "internal only";
  display: inline-block;
  position: absolute;
  top: -2px;
  right: -2px;
  background: $qvo-color-grey-100;
  padding: 0.5em 1em;
  border-radius: 0 8px;
}
</style>