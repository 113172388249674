<template>
  <div>
    <radioGroup
      class="radio-buttons"
      :class="type === 'YesNo' ? 'yes-no' : 'radio'"
      :rules="isOptional ? [] : [rules]"
      :value="value"
      @change="[setAnswer($event)]"
      :disabled="disabled"
    >
      <radio
        v-for="answer in answerList"
        :key="answer.Identifier"
        :label="answer.DisplayText"
        :value="answer.Identifier"
        :disabled="disabled"
      ></radio>
    </radioGroup>
  </div>
</template>

<script>
import radio from "@/qux-common/qux22/components/molecules/radio";
import radioGroup from "@/qux-common/qux22/components/molecules/radio-group";

export default {
  components: {
    radio,
    radioGroup,
  },
  props: {
    /**
     * Available answers to select
     */
    answerList: {
      type: Array,
    },
    type: {
      type: String,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: (v) => !!v || "Selection is required",
    },
  },
  methods: {
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      this.$emit("setAnswer", answer);
    },
  },
};
</script>

<style lang="scss" scoped>
.yes-no {
  &::v-deep {
    .v-input--radio-group__input .radio-fields {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    .v-radio {
      margin-right: 1em;
    }
  }
}
</style>