<template>
  <div>
    <qvoInput
      :multiline="multiline"
      class="text-field"
      :value="value"
      :isOptional="isOptional"
      :rules="isOptional ? [] : [rules]"
      :required="!isOptional"
      @change="[setAnswer($event)]"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import qvoInput from "@/qux-common/qux22/components/molecules/input";

export default {
  components: {
    qvoInput,
  },
  props: {
    multiline: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: (v) => !!v || "Value is required",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Sets the answer
     */
    setAnswer(answer) {
      this.$emit("setAnswer", answer);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>