<template>
  <qvoButton text class="back-button" v-bind="$attrs" v-on="$listeners">
    <v-icon>mdi-arrow-left</v-icon>
  </qvoButton>
</template>
  
<script>
import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    qvoButton,
  },
};
</script>
  
<style lang="scss" scoped>
.back-button.v-btn.v-size--default {
  min-width: unset;
  padding: 0;
  &::v-deep {
    .v-icon {
      color: #0000008a;
    }
  }
}
</style>